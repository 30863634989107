<template>
  <div class="lds-dual-ring"></div>
</template>
<style scoped>
  .lds-dual-ring {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin: 0 10px;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 20px;
    height: 20px;
    margin: 3px;
    border-radius: 50%;
    border: 2px solid #aaa;
    border-color: #aaa transparent #aaa transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
