<template>
<div v-if="date" class = "mask">
  <div class="flex-col material-card text-gray-900 dark:text-white inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
    <date-picker v-model="setectedDate"/>
    <button
        type="submit"
        class="w-full mt-3 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        v-on:click="submit">
        決定
    </button>
  </div>
</div>
</template>
<style scoped>
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height:100vh;
    background-color: gray;
    /* opacity: 0.2; */
    z-index: 998;
  }
  .material-card {
    position: absolute;
    top: 10%;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 999;
    width: 250px;
    /* opacity: 1.0; */
  }
</style>
<script>
import Base from '@/mixins/Base.js'
import { DatePicker } from 'v-calendar';
export default {
    mixins: [Base],
    data(){
        return {
            setectedDate: this.date,
        }
    },
    components: {
        DatePicker
    },
    props: {
        date: { type: Object },
    },
    filters: {
        
    },
    methods: {
        submit(){
            this.$emit('close', this.setectedDate)
        }
    }
}
</script>