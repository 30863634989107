
import moment from 'moment'

function displayDatetime (value) {
    const d = moment(value)
    if (!d.isValid()){
        return '-'
    }else{
        return moment(value).format('YYYY/MM/DD HH:mm')
    }

}

export {displayDatetime}