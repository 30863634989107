<template>
  <div
    class="p-2 h-100 d-flex flex-column"
  >
    <!-- <task-modal
      :task="selectedTask"
      v-on:close="selectedTask = null"
    /> -->
    <div v-if="userInfo.job_class.match(/M/)" class="text-left m-2">
      <div class="">
        受注工事登録
      </div>
      <div class="m-4">
        <label for="taskname">工事名</label>
        <input
          type="text"
          v-model="form.taskname"
          required="required"
          class="w-full block border bg-white border-gray-700 mb-2 p-1"/>
        <button
          type="submit"
          class="w-full mt-3 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          v-on:click="newtask">
          <loader v-if="loadingLocal" />
          <div 
            v-else
          >登録</div>
        </button>
      </div>
    </div>
    <hr>
    <div class="w-full flex-grow overflow-x-auto overflow-y-auto">
      <table class="absolute text-sm border-collapse w-full" >
        <thead>
          <tr class="border-b-2">
            <th>工事名</th>
            <!-- <th>更新日時</th> -->
            <th>登録日時</th>
            <th>進捗</th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-on:click="selecttask(task.id)"
            v-for="task in tasks" 
            :key="task.id"
            class="border-b hover:bg-gray-50 cursor-pointer"
          >
            <td>{{ task.task_name }}</td>
            <!-- <td>{{ displayDatetime(task.processed_at) }}</td> -->
            <td style="white-space: nowrap">
              {{ displayDatetime(task.uploaded_at) }}
            </td>
            <td>{{ task.status }}</td>
            <!-- <td>
              <button
                size="sm"
                class="mr-1"
                :disabled="deletingTask === task.id"
                variant="danger"
                v-on:click="deletetask($event, task.id)"
              >
                <loader v-if='deletingTask === task.id'/>
                <font-awesome-icon
                  v-else
                  icon="trash"
                />
              </button>
            </td>
            <td>{{ task.error }}</td> -->
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<style scoped>
  td, th {
    vertical-align: middle!important;
    white-space: nowrap;
    padding: 5px 5px;
  }
  button.checkout-button {
    background-color: white;
  }
  button.checkout-button > img {
    width: 50px;
    height:
    auto
  }
  input {
    width: 100%;
    margin-bottom: 10px;
    border: none;
    outline: none;
    padding: 10px;
    font-size: 13px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 4px;
  }
</style>
<script>
import Base from '@/mixins/Base.js'
import loader from "@/components/Loader.vue"
// import TaskModal from '@/views/Tasks/TaskModal.vue'
// import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
import { displayDatetime } from '@/utils/functions';
// const FilePond = vueFilePond()

export default {
  components: {
    loader,
  },
  mixins: [Base],

  data() {
    return {
      loadingLocal: false,
      error: "",
      form: {
        taskname: "",
      },
      onoff: {
        "main_view": false,
        "file_view": false,
        "material_view": false,
        "baseace_view": false,
        "pillar_view": false,
        "base_view": false,
        "point_view": false,
      },
      selectedTask: null,
      deletingTask: null,
      requestingTask: null,
      intId: null
    };
  },
  computed: {
    cases: function () {
      if (!this.tasks) return []
      return this.tasks
    },
    uploadable: function () {
      if (!this.tasks) return null
      return this.userInfo.job_class.match(/M/)
    },
  },
  methods: {
    async newtask() {
      this.loadingLocal = true;
      try {
        await this.$store.dispatch("tasks/addTask", this.form)
        this.checktasks()
        this.loadingLocal = false
        this.form.taskname = ''
        return;
      } catch (err) {
        const { status, data } = err.response
        if (status === 400) {
          this.error = "Oops, add task failed"
        } else {
          this.error = "Unexpected error:" + JSON.stringify(data)
        }
        this.loadingLocal = false
        return;
      }
    },
    selecttask: function (id) {
      this.loading = true
      this.$store.dispatch('canvas/setImage', null)
      this.$store.dispatch('tasks/setActiveId', id)

      this.onoff.main_view = true
      this.onoff.file_view = false
      this.onoff.material_view = false
      this.onoff.baseace_view = false
      this.onoff.pillar_view = false
      this.onoff.base_view = false
      this.onoff.point_view = false
      this.$store.dispatch('construction/onoffView', this.onoff)

      this.$router.push({ name: 'Construction'})
    },
    archive: async function (id) {
      await this.$store.dispatch('tasks/archive', id)
    },
    async checktasks() {
      try {
        await this.$store.dispatch("tasks/fetch")
      } catch (err) {
        console.error(err)
        throw err
      }
      return;
    },
  },
  async mounted () {
    this.loading = true
    await this.$store.dispatch('tasks/fetch')
    this.loading = false
    const self = this
    this.intId = window.setInterval(() => {
      self.$store.dispatch('tasks/fetch')
    }, 5000)
  },
  beforeUnmount () {
    if (this.intId) {
      window.clearInterval(this.intId)
    }
  },
  setup () {
    return {
      displayDatetime
    }
  }
}
</script>
