<template>
<div v-if="material" class = "mask">
  <div class="material-card text-gray-900 dark:text-white inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
    <div class="bg-white dark:bg-gray-600 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
      <select
        class="bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        v-model="setectedData"
      >
        <option value="new">new</option>
        <option
          v-for="(g, i) in groupList"
          :key="i"
          :value="g"
        >
          {{ g }}
        </option>
      </select>

      <button
        v-if='setectedData!=="new"'
        @click="deleteGroup"
        type="button"
        class="ml-3 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
        削除
      </button>
      <div class="pt-3">
        <table class="border-collapse w-full text-sm">
          <thead>
            <th class = "border" v-for="(d, i) in displayKeys" :key="i">{{d.display}}</th>
            <th class = "border">必要本数</th>
          </thead>
          <tbody>
            <tr v-for="(m, i) in material" :key="m.processing_no">
              <td class = "border" v-for="(d, j) in displayKeys" :key="j">{{ m[d.key] }}</td>
              <td class = "border">
                <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="number"  v-model="requiredNum[i]">
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="bg-gray-50 dark:bg-gray-500 py-3 flex justify-end">
      <span class="p-2"
      v-if='setectedData==="new"'
      >
        <label for="groupname pr-3">グループ名</label>
        <input class="shadow border rounded text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="groupname" type="text" v-model="groupName" >
      </span>
      <button
        @click="submit"
        type="button"
        class="ml-3 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        決定
      </button>
      <button
        @click="cancelClick"
        type="button"
        class="ml-3 mr-3 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
        キャンセル
      </button>
    </div>
  </div>
</div>
</template>
<style scoped>
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height:100vh;
    background-color: gray;
    /* opacity: 0.2; */
    z-index: 998;
  }
  .material-card {
    position: absolute;
    top: 10%;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 999;
    width: 80%;
    max-width: 600px;
    /* opacity: 1.0; */
  }
</style>
<script>
import Base from '@/mixins/Base.js'
export default {
  mixins: [Base],
  data () {
    return {
      setectedData: 'new',
      groupName: '',
      displayKeys: [
        { key: 'processing_no', display: '加工帳行' },
        { key: 'skelton_page_no', display: '躯体ページ' },
        { key: 'shape_no', display: '形状番号' },
        { key: 'diameter', display: '径' },
        { key: 'material', display: '材質' },
        { key: 'cutting_dimention', display: '切寸法' },
        { key: 'cutting_total_num', display: '切寸合計本数' },
        { key: 'standard_length', display: '定尺長さ' },
        { key: 'divided_num', display: '割数' },
        { key: 'standard_length', display: '定尺長さ' },
        { key: 'divided_num', display: '割数' },
        { key: 'standard_num', display: '定尺本数' }
      ],
      // materialの配列数の割り当てが不明だったので、ありえないデータ数(500)で設定
      requiredNum: new Array(500)
    }
  },
  watch: {
    setectedData: function(){
      this.groupName = this.setectedData == "new" ? "" : this.setectedData 
      if (this.setectedData == "new"){
        for (let i=0; i<this.material.length; i++) {
          this.requiredNum[i] = ''
        }
      }else{
        // グループ情報の表示
        for (let i=0; i<this.material.length; i++){
          let m = this.material[i]
          for (let j=0; j<this.materialsgroup.length; j++){
            let mg = this.materialsgroup[j]
            if (mg['group_name']==this.setectedData){
              if (m.processing_no==mg.processing_no){
                this.requiredNum[i] = mg.required_num
              }
            }
          }
        }
      }
      
    },
  },
  props: {
    material: { type: Object },
  },
  computed:{
    groupList: function(){
      let groups = []

      let type = this.material[0].parent_data
      for (let i=0; i<this.materialsgroup.length; i++){
        let mg = this.materialsgroup[i]
        if (mg.parent_data==type){
          groups.push(this.materialsgroup[i]['group_name'])
        }
      }
      return Array.from(new Set(groups))
    }
  },
  methods: {
    async deleteGroup(){
      let type = this.material[0].parent_data
      await this.$store.dispatch('tasks/deleteGroup', 
                                  {id: this.activeTaskId,
                                  type: type,
                                  groupname: this.groupName})
      await this.$store.dispatch('materialsgroup/fetch', this.activeTaskId)
      alert("削除完了")
      this.setectedData = 'new'
    },
    cancelClick: function(){
      this.setectedData = "new"
      this.requiredNum = new Array(500)
      this.$emit('close')
    },
    async submit(){
      // console.log(this.groupName)
      if (this.groupName){
        let data = {}
        for (let i=0; i<this.material.length; i++) {
          let m = this.material[i]
          data[m.processing_no] = this.requiredNum[i] ? this.requiredNum[i] : 0
        }
        // console.log(data)
        let type = this.material[0].parent_data
        if (this.setectedData=="new"){
          await this.$store.dispatch('tasks/addGroup', 
                                      {id: this.activeTaskId,
                                      type: type,
                                      data: data,
                                      groupname: this.groupName})
        }else{
          await this.$store.dispatch('tasks/addGroup', 
                                      {id: this.activeTaskId,
                                      type: type,
                                      data: data,
                                      groupname: this.setectedData})
        }
        this.setectedData = "new"
        this.requiredNum = new Array(500)
        this.$emit('close')
      }else{
        alert("グループ名を入力してください")
      }
    }
  }
}
</script>
