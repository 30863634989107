<template>
<div 
  v-if="!date"
  class='border border-black-500 rounded shadow-lg p-1 bg-gray-100 text-black-500 text-left'
  style="position:absolute; z-index:9999" 
  :style="{ top: '86px',  left: '250px' }"
>
  <ul class="p-1">
    <li
      v-for="(g, i) in this.groupList"
      :key="i+'infoGroup'"
    >
      <span :style="{color: LINECOLORS[i]}">●</span>
      {{ g }}
    </li>
  </ul>
</div>
<div 
  v-if="this.tooltip & this.dragging==false"
  class='tooltip rounded shadow-lg p-1 bg-gray-100 text-black-500 text-left'
  style="position:absolute" 
  :style="{ top: absoMouY-50+'px',  left: absoMouX+'px' }"
  v-html="toolmsg"
>
</div>
<div 
  class="block"
  v-if="this.contextmenu"
  style="position:absolute" 
  :style="{ top: absoMouY-50+'px',  left: absoMouX+'px' }"
  > 
  <div class="bg-white w-30 rounded-lg flex flex-col text-sm text-gray-500 shadow-lg">

    <div class="m-2">
      <label for="date">{{ moment(this.grabmgp.exec_date, 'YYYY/MM/DD') }}</label>
      <button class="pr-1 pl-2" name="date" v-on:click="showCalenderModal">
        <font-awesome-icon icon="calendar"/>      
      </button>
    </div>
    <button
          class="m-2 p-1 bg-white hover:bg-red-500 text-red-700 font-semibold hover:text-white border border-red-500 hover:border-transparent rounded"
          @click="deleteMgp"
      >
        削除
    </button>
  </div>
</div>
<select-date-modal
      :date="date"
      v-on:close="endSelectData"
/>
  <div 
    class="h-full"
    :style="{ 'cursor': cursor}"
    >
    <div class="m-2 min-w-550">
      <span class="font-bold mr-2">登録対象</span>
      <select
          class="bg-gray-200 border border-gray-200 text-gray-700 py-1 px-2 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          v-model="this.setectedData">
        <option value="基礎エース計画">基礎エース計画</option>
        <option value="ベース計画">ベース計画</option>
        <option value="柱組立計画">柱組立計画</option>
      </select>
      <button
          class="ml-3 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded"
          @click="fitWindow"
      >
        フィット
      </button>
      <button
        class="ml-3 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded"
        @click="addPoint"
        v-if="mode==='clean'"
      >
        ポイント追加
      </button>
      <button
        class="ml-3 bg-blue-500 text-white-700 font-semibold text-white py-1 px-2 border border-transparent rounded"
        @click="resetPoint"
        v-else-if="mode==='add'"
      >
        ポイント追加中
      </button>
      <button
        class="ml-3 bg-transparent hover:bg-green-500 text-green-700 font-semibold hover:text-white py-1 px-2 border border-green-500 hover:border-transparent rounded"
        @click="edit"
        v-if="mode==='clean'"
      >
        編集
      </button>
      <button
        class="ml-3 bg-green-500 text-white-700 font-semibold text-white py-1 px-2 border border-transparent rounded"
        @click="resetEdit"
        v-else-if="mode==='edit'"
      >
        編集中
      </button>
      <select
        class="mr-3 bg-gray-200 border border-gray-200 text-gray-700 py-2 px-2 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        v-model="setectedGroup"
        v-if="mode==='add'"
      >
        <option
          v-for="(g, i) in this.groupList"
          :key="i"
          :value="g"
        >
          {{ g }}
        </option>
      </select>
    </div>
    <div
        ref="wrapper"
        style="height: calc(100% - 65px)"
        class="w-full">
      <canvas
          ref="canvas"
          class="mx-auto"
          :top=0
          :left=0
          :height="canvasSize.h"
          :width="canvasSize.w"
          v-on:dblclick="fitWindow"
          v-on:mouseup="myUp"
          v-on:mousedown="myDown"
          v-on:mousemove="myMove"
          v-on:mousewheel="onWheel"
          v-on:click.right.prevent="onClickRight"
      >
      </canvas>
    </div>
  </div>
</template>

<script>
import Base from '@/mixins/Base.js'
import CanvasMixin from '@/mixins/Canvas.js'
import moment from 'moment'
import SelectDateModal from '@/views/Construction/SelectDateModal.vue'

export default {
  mixins: [Base, CanvasMixin],
  data() {
    return {
      setectedData: '',
      currentUrl: null,
      materialFile: null,
      setectedGroup: '',
      date: null,
      LINECOLORS: [
        'aqua', //aqua
        'red', // red
        'darkviolet', // purple
        'green', //green
        'fuchsia', // fuchsia
      ],
    }
  },
  components: {
    SelectDateModal
  },
  computed:{
  },
  watch: {
    setectedData: function () {
      this.currentUrl = ""
      this.setectedGroup = ""
      this.$store.dispatch('tasks/setActivePlan', this.setectedData)
      this.$store.dispatch('tasks/setActiveGroup', this.setectedGroup)
      if (this.setectedData == '基礎エース計画') {
        this.currentUrl = this.activeTask.base_url
        // ベースと柱は同じ図面を使う
      } else if (this.setectedData == 'ベース計画') {
        this.currentUrl = this.activeTask.pillar_url
      } else if (this.setectedData == '柱組立計画') {
        this.currentUrl = this.activeTask.pillar_url
      }
      this.chageGroupList()
      this.changeImage()
    },
    setectedGroup: function(){
      this.$store.dispatch('tasks/setActiveGroup', this.setectedGroup)
    },
    scale: function () {
      if (this.img !== null) {
        this.draw()
        this.$store.dispatch('canvas/setPrevScale', this.scale)
        this.$store.dispatch('canvas/setToolTip', false)
        this.$store.dispatch('canvas/setContextMenu', false)
      }
    },
  },
  async mounted() {
    const cont = this.$refs.wrapper
    const contH = cont.clientHeight
    const contW = cont.clientWidth
    // console.log(cont)
    this.loadingLocal = false
    // window.addEventListener('resize', this.handleResize)
    await this.$store.dispatch('materials/fetch', this.activeTaskId)
    await this.$store.dispatch('materialsgroup/fetch', this.activeTaskId)
    await this.$store.dispatch('materialsgrouppoint/fetch', this.activeTaskId)
    
    this.$store.dispatch('mouse/setMode', 'clean')
    // canvasの設定
    this.currentUrl = this.activeTask.base_url
    this.$store.dispatch('canvas/setImage', await this.addImageProcess(this.getFullUrl(this.currentUrl)))
    // this.$store.dispatch('canvas/setImage', null)
    if (contH > 0) {
      this.$store.dispatch('canvas/setSize', {h: contH, w: contW})
      this.$store.dispatch('mouse/setLastImgPosition', { x: 0, y: 0 })
    }
    this.$store.dispatch('canvas/setContext', this.$refs.canvas.getContext('2d'))
    this.$store.dispatch('canvas/setScale', this.canvasRatio.x)
    this.context.img = null
    // this.draw()
  },
  beforeUnmount: function () {
    // window.removeEventListener('resize', this.handleResize)
    this.$store.dispatch('canvas/setImage', null)
    this.$store.dispatch('tasks/setActivePlan', "")
    this.$store.dispatch('tasks/setActiveGroup', "")
    this.$store.dispatch('canvas/setContextMenu', false)
    this.$store.dispatch('materialsgroup/setGroupList', [])
    // console.log("beforeUnmount")
  },
  methods: {
    showCalenderModal: function(){
      this.date = this.grabmgp.exec_date ? this.grabmgp.exec_date : new Date()
    },
    transStrDate: function(date){
      var today = new Date(date)
      var year = today.getFullYear();
      var month = today.getMonth() + 1;
      var day = today.getDate();
      return year + '/' + month + '/' + day
    },
    async endSelectData(e){

      this.grabmgp.exec_date = e
      // console.log(this.grabmgp.exec_date)
      
      await this.$store.dispatch('materialsgrouppoint/addExeDate', {id:this.grabmgp.id, d:this.grabmgp.exec_date})

      this.$store.dispatch('canvas/setContextMenu', false)
      this.date = null
    },
    moment(date, format) {
      return moment(date).format(format)
    },
    async deleteMgp(){
      // console.log(this.grabmgp)
      if (this.grabmgp){
        this.$store.dispatch('mouse/setCursor', 'wait')
        await this.$store.dispatch('materialsgrouppoint/delete', this.grabmgp.id)
        await this.$store.dispatch('materialsgrouppoint/fetch', this.activeTaskId)
        this.$store.dispatch('canvas/setContextMenu', false)
        this.$store.dispatch('mouse/setCursor', 'crosshair')
      }
    },
    chageGroupList: function(){
      let groups = []
      
      let type = this.setectedData
      for (let i=0; i<this.materialsgroup.length; i++){
        let mg = this.materialsgroup[i]
        if (mg.parent_data==type){
          groups.push(this.materialsgroup[i]['group_name'])
        }
      }
      // console.log(groups)
      this.$store.dispatch('materialsgroup/setGroupList', Array.from(new Set(groups)))
      return Array.from(new Set(groups))
    },
    addPoint: function(){
      this.$store.dispatch('mouse/setMode', 'add')

      this.$store.dispatch('tasks/chageStatus', 
                            {id: this.activeTaskId,
                            status: "工事箇所登録"})
    },
    resetPoint: function(){
      this.$store.dispatch('mouse/setMode', 'clean')
    },
    edit: function(){
      this.$store.dispatch('mouse/setMode', 'edit')
    },
    resetEdit: function(){
      this.$store.dispatch('mouse/setMode', 'clean')
    },
    async changeImage(){

      if (this.setectedData == ""){
        this.clearCanvas()
        this.$store.dispatch('canvas/setImage', null)
      }else{
        this.$store.dispatch('canvas/setImage', await this.addImageProcess(this.getFullUrl(this.currentUrl)))
        this.draw()
      }
    },
    handleResize: function() {
      const cont = this.$refs.wrapper
      console.log(cont)
      const contH = cont.clientHeight
      const contW = cont.clientWidth
      if (contH > 0 && !this.resized) {
        console.log("handleResize")
        this.$store.dispatch('canvas/setSize', {h: contH, w: contW})
        this.draw()
      }
    },
    fitWindow: function(){
      if (this.setectedData == ""){
        this.clearCanvas()
        this.$store.dispatch('canvas/setImage', null)
      }else{
        this.draw()

        const cont = this.$refs.wrapper
        const contH = cont.clientHeight
        const contW = cont.clientWidth
        if (contH > 0) {
          this.$store.dispatch('canvas/setSize', {h: contH, w: contW})
        }
        this.$store.dispatch('mouse/setLastImgPosition', { x: 0, y: 0 })
        this.$store.dispatch('canvas/setScale', this.canvasRatio.x)
        this.$store.dispatch('canvas/setPrevScale', this.canvasRatio.x)
        this.draw()
      }
    },
    onWheel: function ($event) {
      const delta = $event.wheelDelta
      if (delta > 0) {
        if (this.scale > 2) return
        this.$store.dispatch('canvas/scaleUp')
      } else {
        if (this.scale < 0.1) return
        this.$store.dispatch('canvas/scaleDown')
      }
    },
    async checkmaterials() {
      try {
        await this.$store.dispatch("materials/fetch", this.activeTaskId)
      } catch (err) {
        console.error(err)
        throw err
      }
      return;
    },

  }
}
</script>
