<template>
    <div class="shadow p-2 mb-3">
      <span><b>基礎エース図面</b></span><br>
      <div 
        class="bg-rose-300"
        v-if="this.activeTask.base_filename"
      >
        <a>
          ファイル:{{ this.activeTask.base_filename }}<br>
          <loader v-if='deletingBase'/>
          <font-awesome-icon 
            v-else
            v-on:click="deletebase($event, taskid)"
            class="cursor-pointer"
            icon="trash"/>
        </a>

        <img 
          class="object-contain h-48 w-full" 
          v-bind:src="getFullUrl(this.activeTask.base_url)">
      </div>
      <file-pond
          v-else
          name="file"
          ref="pond"
          class-name="my-pond"
          label-idle="図面画像選択"
          allow-multiple="false"
          allowRevert="false"
          v-bind:server="filepondServerBase"
          v-on:processfile="onfileuploaded"
          v-on:error="onfileuploaderror"
        />
    </div>
    <div class="shadow p-2 mb-3">
      <span><b>柱組立図面</b></span><br>
      <div 
        class="bg-rose-300"
        v-if="this.activeTask.pillar_filename"
      >
        <a>
          ファイル:{{ this.activeTask.pillar_filename }}<br>
          <loader v-if='deletingPillar'/>
          <font-awesome-icon 
            v-else
            v-on:click="deletepillar($event, taskid)"
            class="cursor-pointer"
            icon="trash"/>
        </a>

        <img 
          class="object-contain h-48 w-full" 
          v-bind:src="getFullUrl(this.activeTask.pillar_url)">
      </div>
      <file-pond
          v-else
          name="file"
          ref="pond"
          class-name="my-pond"
          label-idle="図面画像選択"
          allow-multiple="false"
          allowRevert="false"
          v-bind:server="filepondServerPillar"
          v-on:processfile="onfileuploaded"
          v-on:error="onfileuploaderror"
        />
    </div>
</template>

<script>
import Base from '@/mixins/Base.js'
import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
const FilePond = vueFilePond()
import loader from "@/components/Loader.vue"

export default{
    mixins: [Base],
    data(){
        return{
          deletingBase: false,
          deletingPillar: false,
        }
    },
    components: {
      FilePond,
      loader
    },
    computed: {
      constructionName: function(){
        return this.activeTask.task_name
      },
      filepondServerBase: function(){
        return this.filepondServer('base')
      },
      filepondServerPillar: function(){
        return this.filepondServer('pillar')
      },
    },
    methods: {
      onfileuploaded: function () {
        this.$store.dispatch('tasks/fetch')
        this.$refs.pond.removeFile()
      },
      onfileuploaderror: function (err) {
        alert(JSON.stringify(err))
      },
      deletebase: function(){
        if (!window.confirm('ファイルを削除しますか?')) return
        this.deletingBase=true
        this.$store.dispatch('tasks/deleteBase', this.activeTaskId)
        this.deletingBase=false
      },
      deletepillar: function(){
        if (!window.confirm('ファイルを削除しますか?')) return
        this.deletingBase=true
        this.$store.dispatch('tasks/deletePillar', this.activeTaskId)
        this.deletingBase=false
      },
      filepondServer: function(type){
        return {
          process: (fieldName, file, metadata, load, error, progress, abort) => {
            const formData = new FormData()
            formData.append("type", type);
            formData.append(fieldName, file, file.name)
            const request = new XMLHttpRequest()
            request.timeout = 60 * 1000
            // request.setRequestHeader('ContentType', 'multipart/form-data')
            request.open('PATCH', `${process.env.VUE_APP_ENDPOINT}/task/${this.activeTaskId}/`)
            request.setRequestHeader('Authorization', `Token ${this.token}`)
            request.upload.onprogress = (e) => {
              progress(e.lengthComputable, e.loaded, e.total)
            }
            request.onload = function () {
              if (request.status >= 200 && request.status < 300) {
                load(request.responseText)
              } else {
                // Can call the error method if something is wrong, should exit after
                error('oh no')
              }
            }
            request.send(formData)
            return {
              abort: () => {
                // This function is entered if the user has tapped the cancel button
                request.abort()

                // Let FilePond know the request has been cancelled
                abort()
              }
            }
          },
          fetch: null,
          load: null
        }
      },
    },
}
</script>