// import { format } from "core-js/core/date"

import moment from 'moment'

export default {
  data () {
    return {
      grabing: false,
      LINE_WIDTH: 8,
      DOT_DIA: 12,
      DOT_DIA2: 15,
      GRAB_DIA: 22,
      LINECOLORS: [
        'rgb(0, 0, 255, 0.7)', //aqua
        'rgb(255, 0, 0, 0.7)', // red
        'rgb(148, 0, 211, 0.7)', // darkviolet
        'rgb(0, 128, 0, 0.7)', //green
        'rgb(255, 0, 255, 0.7)', // fuchsia
      ],
      pich_dist: 0,
      tapCount: 0,
    }
  },
  methods: {
    moment(date, format) {
      return moment(date).format(format)
    },
    drawRect: function (centroid) {
      const w = 6/ this.scale
      const h = 6/ this.scale
      this.context.fillRect(centroid.x - w / 2, centroid.y - h / 2, w, h)
      this.context.strokeStyle = 'rgb(50, 50, 50, 1.0)'
      // this.context.lineWidth = 2 / this.scale
      this.context.strokeRect(centroid.x - w / 2, centroid.y - h / 2, w, h)
    },
    drawCheck: function (centroid) {
      const w = 6/ this.scale
      const h = 6/ this.scale
      this.context.beginPath();
      this.context.strokeStyle = 'rgb(50, 50, 50, 1.0)'
      this.context.moveTo(centroid.x - w / 3, centroid.y - h / 5);
      this.context.lineTo(centroid.x , centroid.y + h / 2);
      this.context.lineTo(centroid.x + w / 1.5, centroid.y - h / 3);
      this.context.stroke();
    },
    drawCircle: function (centerX, centerY, exist_date, done, checked) {
      const D = this.DOT_DIA
      const dia = D / this.scale
      const D2 = this.DOT_DIA2
      const dia2 = D2 / this.scale
      if (done){

        this.context.beginPath()
        this.context.arc(centerX, centerY, dia2 / 2, 0, 2 * Math.PI)
        this.context.closePath()
        this.context.fill()
        if (checked){
          this.drawCheck({ x: centerX, y: centerY })
        }
      }else{

        this.context.beginPath()
        this.context.arc(centerX, centerY, dia / 2, 0, 2 * Math.PI)
        this.context.closePath()
        this.context.stroke()
        if (exist_date){
          this.drawRect({ x: centerX, y: centerY })
        }
      }
    },
    drawCurve: function (ptsa) {
      this.context.beginPath()
      this.drawLines(ptsa)
      // this.context.stroke()
    },
    drawLines: function (pts) {
      this.context.moveTo(pts[0], pts[1])
      for (let i = 2; i < pts.length - 1; i += 2) {
        this.context.lineTo(pts[i], pts[i + 1])
      }
      this.context.stroke()
    },
    drawAngle: function (center, pt1, pt2, letter) {
      this.context.beginPath()
      if (pt1) {
        this.context.moveTo(pt1.x, pt1.y)
        this.context.lineTo(center.x, center.y)
      }
      if (pt2) {
        this.context.moveTo(pt2.x, pt2.y)
        this.context.lineTo(center.x, center.y)
      }
      if (pt1 && pt2) {
        let ang1 = Math.atan2(pt1.y - center.y, pt1.x - center.x)
        let ang2 = Math.atan2(pt2.y - center.y, pt2.x - center.x)
        if (ang1 < 0) ang1 += 2 * Math.PI
        if (ang2 < 0) ang2 += 2 * Math.PI
        const arcDia = 5 / this.scale * this.canvasRatio.x
        if ((ang1 < ang2 && ang2 - ang1 <= Math.PI) || (ang1 >= ang2 && ang1 - ang2 > Math.PI)) {
          this.context.arc(center.x, center.y, arcDia, ang1, ang2)
        } else {
          this.context.arc(center.x, center.y, arcDia, ang2, ang1)
        }
        let absAng = Math.abs(ang1 - ang2)
        if (absAng > Math.PI) absAng = 2 * Math.PI - absAng
        this.$store.dispatch(`angle/setAngle${letter}Deg`, parseInt(absAng * 180 / Math.PI))
      }
      this.context.stroke()
    },
    getFlatCoords: function (coords) {
      var ret = []
      coords.forEach(elem => {
        ret.push(elem.x)
        ret.push(elem.y)
      })
      return ret
    },
    transStrDate: function(date){
      var today = new Date(date)
      var year = today.getFullYear();
      var month = today.getMonth() + 1;
      var day = today.getDate();
      return year + '/' + ('00'+month).slice(-2) + '/' + ('00'+day).slice(-2) 
    },
    addImageProcess: function (src) {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = () => resolve(img)
        img.onerror = reject
        img.src = src
      })
    },
    scaleCanvas (offset) {
      // const offset = (this.scale - this.canvasRatio.x) * this.IMAGE_PIX_SIZE_X / 2
      // console.log('-------scaleCanvas-------')
      if (this.dragging){
        if (!this.grabing){
          const offsetX = this.lastImgX + (this.prevMouX-this.lastMouX)
          const offsetY = this.lastImgY + (this.prevMouY-this.lastMouY)
          this.$store.dispatch('mouse/setLastImgPosition', { x: offsetX, y: offsetY } )
        }
        this.$store.dispatch('mouse/setPrevMousePosition', { x: this.lastMouX , y: this.lastMouY } )
      }else{
        // var centerX = this.lastImgX + this.img.width/2*(this.scale - this.canvasRatio.x)
        // var centerY = this.lastImgY + this.img.height/2*(this.scale - this.canvasRatio.x)
        // const offsetX = this.lastImgX - (this.lastImgX + this.lastMouX) * this.scale
        // const offsetY = this.lastImgY - (this.lastImgY + this.lastMouY) * this.scale
        // TODO マウス位置に拡大縮小できるように
        let oriwidth = this.img.width*this.scale
        let oriheight = this.img.height*this.scale
        // console.log(oriwidth + "," + this.prevscale + "," + (this.lastMouX+this.lastImgX)/this.img.width)
        const offsetX = this.lastImgX + (this.lastMouX+this.lastImgX)/oriwidth * (this.scale-this.prevscale)*oriwidth
        const offsetY = this.lastImgY + (this.lastMouY+this.lastImgY)/oriheight * (this.scale-this.prevscale)*oriheight 
        if (offset){
          this.$store.dispatch('mouse/setLastImgPosition', { x: offsetX, y: offsetY } )
        }
      }
      // console.log('this.lastMou: ' + this.lastMouX + ',' + this.lastMouY)
      // console.log('this.lastImg: ' + this.lastImgX.toFixed(4) + ',' + this.lastImgY.toFixed(4))
      // console.log('lastImgX: ' + -parseInt(this.lastImgY) + ',' + -parseInt(this.lastImgY))
      this.context.translate(-parseInt(this.lastImgX), -parseInt(this.lastImgY))
      this.context.scale(this.scale, this.scale)
    },
    unscaleCanvas () {
      // const offsetX = (this.scale - this.canvasRatio.x) * this.lastX
      // const offsetY = (this.scale - this.canvasRatio.x) * this.lastY
      this.context.scale(1 / this.scale, 1 / this.scale)
      this.context.translate(this.lastImgX, this.lastImgY)
      this.context.setTransform(1, 0, 0, 1, 0, 0)
    },
    draw: async function (offset=true) {
      this.clearCanvas(this.context)
      this.context.lineWidth = this.LINE_WIDTH / this.scale * this.canvasRatio.x
      if (this.mode === 'clean' | this.mode === 'add' | this.mode === 'edit') {
        this.scaleCanvas(offset)
        this.context.drawImage(this.img, 0, 0)
        for (const mgp of this.materialsgrouppoint) {
          if (this.activePlan==mgp.parent_data){
            // console.log(this.activeGroup + " " + mgp.x + "," + mgp.y)
            let gindex = this.groupList.indexOf(mgp.group_name)%5
            this.context.fillStyle = this.LINECOLORS[gindex]
            this.context.strokeStyle = this.LINECOLORS[gindex]
            this.drawCircle(mgp.x, mgp.y, mgp.exec_date, mgp.done, mgp.checked)
          }
        }
        this.unscaleCanvas()
      }else if (this.mode === 'plan') {
        this.scaleCanvas(offset)
        this.context.drawImage(this.img, 0, 0)
        for (const mgp of this.activePointList) {
          let gindex = this.groupList.indexOf(mgp.group_name)%5
          this.context.fillStyle = this.LINECOLORS[gindex]
          this.context.strokeStyle = this.LINECOLORS[gindex]
          this.drawCircle(mgp.x, mgp.y, mgp.exec_date, mgp.done, mgp.checked)
        }
        this.unscaleCanvas()
      }
    },
    getPointList(){

      let pointLists = []
      for (let i=0; i<this.materialsgrouppoint.length; i++){
        let mgp = this.materialsgrouppoint[i]
        if (mgp.parent_data==this.activePlan){
          if (this.setectedDate!="ALL"){
            if (this.transStrDate(mgp['exec_date'])==this.setectedDate){
              pointLists.push(mgp)
            }
          }else{
            pointLists.push(mgp)
          }
        }
      }
      return pointLists
    },
    clearCanvas: function () {
      this.context.clearRect(0, 
        0, 
        this.context.canvas.getBoundingClientRect().width, 
        this.context.canvas.getBoundingClientRect().height)
    },
    getDistance: function (p1, p2) {
      return Math.sqrt((p1.x - p2.x) ** 2 + (p1.y - p2.y) ** 2)
    },
    isGrabbable: function (p1, p2) {
      const distance = this.getDistance(p1, p2)
      const dia = this.GRAB_DIA / 2 / this.scale
      return distance < dia
    },
    onClickRight: function(e) {
      if(this.mode === 'edit'){

      if (!this.grabmgp) return
        // var mx = parseInt(e.clientX - this.context.canvas.getBoundingClientRect().left)
        // var my = parseInt(e.clientY - this.context.canvas.getBoundingClientRect().top)
        // console.log(mx + "," + my)
        this.$store.dispatch('mouse/setAbsoMousePosition', { x: parseInt(e.clientX), y: parseInt(e.clientY)})
        
        this.$store.dispatch('canvas/setContextMenu', true)
        this.$store.dispatch('canvas/setToolTip', false)
      }
      
    },
    myDown: async function (e) {
      if (!this.activePlan) return
      // tell the browser we're handling this mouse event
      if (e.button !== 0) return
      e.preventDefault()
      e.stopPropagation()
      // get the current mouse position
      var mx = parseInt(e.clientX - this.context.canvas.getBoundingClientRect().left)
      var my = parseInt(e.clientY - this.context.canvas.getBoundingClientRect().top)
      
      this.commonDown(mx, my)
    },
    myDown2: async function (e) {
      if (!this.activePlan) return
      // tell the browser we're handling this mouse event
      e.preventDefault()
      e.stopPropagation()
      var mx = parseInt(e.touches[0].pageX - this.context.canvas.getBoundingClientRect().left)
      var my = parseInt(e.touches[0].pageY - this.context.canvas.getBoundingClientRect().top)
      const { x, y } = this.getTranslateMousePos({ x: mx, y: my })
      if (e.touches.length <= 1) {
        if (!this.dragging){
          this.grabing = false
          this.$store.dispatch('canvas/setGrabMgp', null)
          for (const mgp of this.activePointList) {
            if (this.activePlan==mgp.parent_data){
              if (this.isGrabbable({ x: mgp.x, y: mgp.y }, { x: x, y: y })) {
                this.grabing = true
                let strDate = this.transStrDate(mgp.exec_date)
                let toolmsg = "工事：" + (mgp.checked ? '済' : '未') + '<br>' + "工事日:" + (mgp.exec_date==null ? '未設定' : strDate)+ "<br>グループ:" + mgp.group_name
                this.$store.dispatch('canvas/setToolMsg', toolmsg)
                this.$store.dispatch('canvas/setGrabMgp', mgp)
              }
            }
          }
          // メニュー表示時には移動しないようにするため
          if (!this.contextmenu){
            this.$store.dispatch('mouse/setAbsoMousePosition', { x: parseInt(e.touches[0].pageX), y: parseInt(e.touches[0].pageY)})
          }
        }
        // get the current mouse position
        this.$store.dispatch('mouse/setLastMousePosition', { x: mx, y: my } )
        this.$store.dispatch('mouse/setPrevMousePosition', { x: mx, y: my } )
        this.commonDown(mx, my)
      // 指2本の2本の指のＸ座標の差とＹ座標の差を加えた値を記録
      } else if (e.touches.length >= 2) {
        var p1 = e.touches[0];
        var p2 = e.touches[1];
        
        if (p1.pageX < p2.pageX){
          mx = p1.pageX + (p2.pageX-p1.pageX)
        }else{
          mx = p2.pageX + (p1.pageX-p2.pageX)
        }
        if (p1.pageY < p2.pageY){
          mx = p1.pageY + (p2.pageY-p1.pageY)
        }else{
          mx = p2.pageY + (p1.pageY-p2.pageY)
        }
        this.$store.dispatch('mouse/setLastMousePosition', { x: mx, y: my } )
        this.$store.dispatch('mouse/setPrevMousePosition', { x: mx, y: my } )
        this.pinch_dist = Math.abs(p1.pageX - p2.pageX) + Math.abs(p1.pageY - p2.pageY);
      }
      
    },
    commonDown: async function(mx, my){
      // コンテキストメニューの非表示
      this.$store.dispatch('canvas/setContextMenu', false)

      let addP = this.getTranslateMousePos({ x: mx, y: my })
      // console.log(addP.x + "," + addP.y)
      this.$store.dispatch('mouse/setDragging', false)
      if (this.mode === 'edit') {
        if (!this.dragging) {
          this.$store.dispatch('mouse/setCursor', 'wait')
          this.$store.dispatch('mouse/setStartPosition', { x: addP.x, y: addP.y })
        }
        this.$store.dispatch('mouse/setDragging', true)
      }else if(this.mode === 'add'){
        if (this.activePlan==null | this.activePlan==""){
          alert("登録対象を選択してください")
          return
        }
        if (this.activeGroup==null | this.activeGroup==""){
          alert("部材のグループを選択してください")
          return
        }

        this.$store.dispatch('mouse/setCursor', 'wait')
        if (addP.x>0 & addP.y>0){

          await this.$store.dispatch('materialsgrouppoint/addPoint', {id:this.activeTaskId, type:this.activePlan, groupname:this.activeGroup, x:addP.x, y:addP.y })
          await this.$store.dispatch('materialsgrouppoint/fetch', this.activeTaskId)
          this.draw()
        }
        this.$store.dispatch('mouse/setCursor', 'default')
      }else if(this.mode === 'plan'){
        if (this.grabmgp){
          
          this.$store.dispatch('canvas/setContextMenu', true)
          this.$store.dispatch('mouse/setCursor', 'wait')
          if (this.userInfo.job_class.match(/M/)){
            if (this.grabmgp.done){
              // this.grabmgp.done = !this.grabmgp.done
              await this.$store.dispatch('materialsgrouppoint/changeState', {id: this.grabmgp.id, job: "M", user: this.userInfo.email})
            }else{
              alert("まだ未完了のポイントです")
            }
          }else if (this.userInfo.job_class.match(/J/)){
            // this.grabmgp.checked = !this.grabmgp.checked
            await this.$store.dispatch('materialsgrouppoint/changeState', {id: this.grabmgp.id, job: "J", user: this.userInfo.email})
          }
          await this.$store.dispatch('materialsgrouppoint/fetch', this.activeTaskId)

          this.$store.dispatch('mouse/setCursor', 'default')
          
          // console.log(this.materialsgrouppoint)
          this.$store.dispatch('materialsgrouppoint/setActivePointList', this.getPointList())
          this.draw()

          this.$store.dispatch('canvas/setGrabMgp', null)
          this.$store.dispatch('canvas/setContextMenu', false)
        }else{

          this.$store.dispatch('mouse/setDragging', true)
        }
        
      }else{
        this.$store.dispatch('mouse/setDragging', true)
      }
    },
    // handle mouseup events
    myUp: async function (e) {

      if (!this.activePlan) return
      // tell the browser we're handling this mouse event
      e.preventDefault()
      e.stopPropagation()

      // clear all the dragging flags
      if (this.mode === 'edit') {
        if (this.dragging) {
          this.$store.dispatch('mouse/setDragging', false)
          if (this.grabmgp){
            this.$store.dispatch('materialsgrouppoint/changePoint', {id: this.grabmgp.id, x:this.grabmgp.x, y:this.grabmgp.y})
          }
          this.$store.dispatch('mouse/setCursor', 'wait')
          // await this.$store.dispatch('results/update')
        }
        this.$store.dispatch('mouse/setCursor', 'grab')

      }else{
        this.$store.dispatch('mouse/setDragging', false)
      }
    },
    getTranslatePos: function (pos) {
      const { x, y } = pos
      // console.log(this.scale.toFixed(4) + ',' + this.canvasRatio.x.toFixed(4) + ',' + this.img.width)
      // console.log(this.scale.toFixed(4) + ',' + this.canvasRatio.y.toFixed(4) + ',' + this.img.height)
      
      //画像の左上の位置 センター位置の場合
      const drawOriginX = (this.scale - this.canvasRatio.x) * this.img.width / 2
      const drawOriginY = (this.scale - this.canvasRatio.x) * this.img.height / 2
      // console.log('drawOriginX: ' + drawOriginX)
      // console.log('drawOriginY: ' + drawOriginY)
      return { x: (drawOriginX + x) / this.scale, y: (drawOriginY + y) / this.scale }
    },
    getTranslateMousePos: function (pos) {
      const { x, y } = pos
      return { x: (x+this.lastImgX) / this.scale, y: (y+this.lastImgY) / this.scale }
    },
    // handle mouse moves
    myMove: function (e) {
      if (!this.activePlan){return}
      e.preventDefault()
      e.stopPropagation()
      // get the current mouse position
      var mx = parseInt(e.clientX - this.context.canvas.getBoundingClientRect().left) //e.offsetX
      var my = parseInt(e.clientY - this.context.canvas.getBoundingClientRect().top)
      const { x, y } = this.getTranslateMousePos({ x: mx, y: my })
      
      // メニュー表示時には移動しないようにするため
      if (!this.contextmenu){
        this.$store.dispatch('mouse/setAbsoMousePosition', { x: parseInt(e.clientX), y: parseInt(e.clientY)})
      }

      this.commonMove(mx, my, x, y)
    },
    // handle mouse moves
    myMove2: function (e) {
      if (!this.activePlan){return}
      e.preventDefault()
      e.stopPropagation()
      

      var p1 = e.touches[0];
      // 指1本の移動距離に応じて画像の左上の座標を算出
      if (e.touches.length <= 1) {
        // get the current mouse position
        var mx = parseInt(e.touches[0].pageX - this.context.canvas.getBoundingClientRect().left) //e.offsetX
        var my = parseInt(e.touches[0].pageY - this.context.canvas.getBoundingClientRect().top)
        const { x, y } = this.getTranslateMousePos({ x: mx, y: my })
        
        // メニュー表示時には移動しないようにするため
        if (!this.contextmenu){
          this.$store.dispatch('mouse/setAbsoMousePosition', { x: parseInt(e.touches[0].pageX), y: parseInt(e.touches[0].pageY)})
        }
        this.commonMove(mx, my, x, y)

      // 指2本の指の間の距離に応じて拡大・縮小率を算出 
      } else if(e.touches.length <= 2) {
          var p2 = e.touches[1];
          var dist = Math.abs(p1.pageX - p2.pageX) + Math.abs(p1.pageY - p2.pageY);
          // var photo_r = dist / this.pinch_dist
          // console.log(photo_r)
          if (dist > this.pinch_dist) {
            if (this.scale > 2) return
            this.$store.dispatch('canvas/scaleUp')
          } else {
            if (this.scale < 0.1) return
            this.$store.dispatch('canvas/scaleDown')
          }
          this.pinch_dist = dist
      }
    },
    commonMove: function (mx, my, x, y) {

      let dx = x - this.startX
      let dy = y - this.startY

      // ドラッグ中&コンテキストメニュ表示 はグラブ判定なし
      if (!this.dragging & !this.contextmenu){
        this.grabing = false
        this.$store.dispatch('canvas/setGrabMgp', null)
        if(this.mode=="plan"){

          for (const mgp of this.activePointList) {
            if (this.activePlan==mgp.parent_data){
              if (this.isGrabbable({ x: mgp.x, y: mgp.y }, { x: x, y: y })) {
                this.grabing = true
                let strDate = this.transStrDate(mgp.exec_date)
                let toolmsg = "工事：" + (mgp.checked ? '済' : '未') + '<br>' + "工事日:" + (mgp.exec_date==null ? '未設定' : strDate)+ "<br>グループ:" + mgp.group_name
                this.$store.dispatch('canvas/setToolMsg', toolmsg)
                this.$store.dispatch('canvas/setGrabMgp', mgp)
              }
            }
          }
        }else{
          for (const mgp of this.materialsgrouppoint) {
            if (this.activePlan==mgp.parent_data){
              if (this.isGrabbable({ x: mgp.x, y: mgp.y }, { x: x, y: y })) {
                this.grabing = true
                let strDate = this.transStrDate(mgp.exec_date)
                let toolmsg = "工事：" + (mgp.checked ? '済' : '未') + '<br>' + "工事日:" + (mgp.exec_date==null ? '未設定' : strDate)+ "<br>グループ:" + mgp.group_name
                this.$store.dispatch('canvas/setToolMsg', toolmsg)
                this.$store.dispatch('canvas/setGrabMgp', mgp)
              }
            }
          }
        }
        
      }
      if (!this.contextmenu){this.$store.dispatch('canvas/setToolTip', this.grabing)}

      if (!this.grabing) this.$store.dispatch('mouse/setCursor', 'crosshair')
      if (this.mode === 'edit') {
        dx = x - this.startX
        dy = y - this.startY
        if (!this.materialsgrouppoint) return
        if (this.dragging) {
          if (this.grabing){
            // for (const mgp of this.materialsgrouppoint) {
            //   if (mgp.isDragging) {
            //     mgp.x += dx
            //     mgp.y += dy
            //   }
            // }
            this.grabmgp.x += dx
            this.grabmgp.y += dy
            this.$store.dispatch('mouse/setStartPosition', { x: x, y: y })
            this.draw()
          }else{
            this.$store.dispatch('mouse/setLastMousePosition', { x: mx, y: my } )
            this.draw()
          }
          
        } else {
          this.$store.dispatch('mouse/setPrevMousePosition', { x: mx, y: my } )
          if (this.grabing) this.$store.dispatch('mouse/setCursor', 'grab')
        }
        this.draw()
      // }else if (this.mode=="plan"){
        
      //   if (this.grabing){
      //     this.$store.dispatch('mouse/setCursor', 'wait')
      //   }else{
      //     this.$store.dispatch('mouse/setCursor', 'wait')
      //   }
      
      }else{
        
        this.$store.dispatch('mouse/setLastMousePosition', { x: mx, y: my } )
        if (this.dragging) {
          this.draw()
        }else{
          this.$store.dispatch('mouse/setPrevMousePosition', { x: mx, y: my } )
        }
      }
      // console.log('after mymove')
    },
    objectifyPoints: function (flat) {
      const ret = []
      for (let i = 0; i < flat.length - 1; i += 2) {
        ret.push({ x: flat[i], y: flat[i + 1] })
      }
      return ret
    },
    calcPolygonArea: function (points) {
      const vertices = this.objectifyPoints(this.getCurvePoints(this.getFlatCoords(points)))
      var total = 0
      for (var i = 0, l = vertices.length; i < l; i++) {
        var addX = vertices[i].x
        var addY = vertices[i === vertices.length - 1 ? 0 : i + 1].y
        var subX = vertices[i === vertices.length - 1 ? 0 : i + 1].x
        var subY = vertices[i].y
        total += (addX * addY * 0.5)
        total -= (subX * subY * 0.5)
      }
      return (Math.abs(total) / this.canvasRatio.x / this.canvasRatio.y).toFixed(1)
    },
    getCentroid: function (pts) {
      if (pts.length === 0) return { x: null, y: null }
      var first = pts[0]
      var last = pts[pts.length - 1]
      if (first.x !== last.x || first.y !== last.y) pts.push(first)
      var twicearea = 0
      var x = 0
      var y = 0
      var nPts = pts.length
      var p1
      var p2
      var f
      for (var i = 0, j = nPts - 1; i < nPts; j = i++) {
        p1 = pts[i]
        p2 = pts[j]
        f = (p1.y - first.y) * (p2.x - first.x) - (p2.y - first.y) * (p1.x - first.x)
        twicearea += f
        x += (p1.x + p2.x - 2 * first.x) * f
        y += (p1.y + p2.y - 2 * first.y) * f
      }
      f = twicearea * 3
      return { x: x / f + first.x, y: y / f + first.y }
    }
  }
}
