<template>
  <div class="w-full flex-grow flex flex-col mx-auto pt-4 pb-4  px-2">
    <div class="grid grid-cols-12 mb-2">
      <div class="col-start-4 col-span-6 shadow">
        <div class="text-lg font-extrabold">ユーザー登録</div>
        <div
          v-if="error !== ''"
          class="bg-red-200 relative text-red-500 m-3 py-3 px-3 rounded-lg"
          dismissible
          variant="danger"
        >
          <font-awesome-icon
            class="mx-2"
            icon="exclamation"
          />
          {{ error }}
        </div>
        <div class="text-left p-4" disabled="false">
          <label for="username">Email</label>
          <input
            type="text"
            v-model="form.username"
            required="required"
            class="w-full block border bg-white border-gray-700 mb-2 p-1"/>
          <label for="password">Password</label>
          <input
            type="password"
            v-model="form.password"
            required="required"
            class="w-full block border bg-white border-gray-600 mb-2 p-1"/>
          <label for="jobClass">職級</label>
          <select v-model="form.jobClass" required="required" name="jobClass" class="ml-2 px-2 py-1">
            <option
              v-for="(c, i) in jobClass"
              :key="i"
              :value="c"
            >
              {{ c }}
            </option>
          </select>
          <button
            type="submit"
            class="w-full mt-3 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            v-on:click="adduser">
            <loader v-if="loadingLocal" />
            <div v-else
            >登録</div>
          </button>
          <!-- <button
            type="submit"
            class="w-full mt-3 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
            v-on:click="checkuser">
            <loader v-if="loadingLocal" />
            <div 
              v-else
            >一覧</div>
          </button> -->
        </div>
      </div>
    </div>
    <hr>
    <div class="w-full flex-grow overflow-x-auto overflow-y-auto">
      <table class="absolute text-sm border-collapse w-full" >
        <thead>
          <!-- <th>ID</th> -->
          <tr class="border-b-2">
            <th>E-mail</th>
            <th>権限</th>
            <th>職級</th>
            <th>アクション</th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="user in users" 
            :key="user.id" 
            class="border-b"
            >
            <!-- <td>{{ user.id }}</td> -->
            <td>{{ user.email }}</td>
            <td>{{ user.is_superuser ? "管理者" : "一般" }}</td>
            <td>
              <select v-model="user.job_class" required="required" name="jobClass" class="ml-2 px-2 py-1">
                <option
                  v-for="(c, i) in jobClass"
                  :key="i"
                  :value="c"
                >
                  {{ c }}
                </option>
              </select>
            </td>
            <td>
              <button
                size="sm"
                class="mr-3 bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-1 rounded"
                :disabled="updatingUser === user.id"
                variant="danger"
                v-on:click="updateuser($event, user.id, user.job_class)"
              >
                <loader v-if="updatingUser === user.id" />
                <span v-else>更新</span>
              </button>
              <button
                size="sm"
                class="mr-1"
                :disabled="deletingUser === user.id"
                variant="danger"
                v-on:click="deluser($event, user.id)"
              >
                <loader v-if="deletingUser === user.id" />
                <font-awesome-icon
                  v-else
                  icon="trash"
                />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  
</template>
<style scoped>
td, th {
    vertical-align: middle!important;
    white-space: nowrap;
    padding: 5px 5px;
}
.login h1 {
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  letter-spacing: 1px;
  text-align: center;
}
input {
  width: 100%;
  margin-bottom: 10px;
  border: none;
  outline: none;
  padding: 10px;
  font-size: 13px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}
/* input:focus {
  box-shadow: inset 0 -5px 45px rgba(100, 100, 100, 0.4),
    0 1px 1px rgba(255, 255, 255, 0.2);
} */
.spinner-border {
  vertical-align: middle !important;
}
</style>
<script>
import loader from "@/components/Loader.vue"
import Base from '@/mixins/Base.js'

export default {
  mixins: [Base],
  data() {
    return {
      loadingLocal: false,
      error: "",
      form: {
        username: "",
        password: "",
        jobClass: "J1",
      },
      userList: [],
      deletingUser: null,
      updatingUser: null,
      jobClass: ['J1', 'J2', 'J3', 'M1', 'M2', 'M3'],
    };
  },
  computed:{
    users: function () {
      if (!this.userList) return []
      return this.userList
    },
  },
  components:{
    loader,
  },
  methods: {
    async adduser() {
      this.loadingLocal = true;
      try {
        var message = ""
        if (this.form.jobClass=="") {
          message = "職級を入力してください\n"
        }
        if (this.form.username=="") {
          message += "Emailを入力してください\n"
        }
        if (this.form.password=="") {
          message += "Passwordを入力してください"
        }
        if (message!=""){
          alert(message)
          this.loadingLocal = false;
          return
        }
        await this.$store.dispatch("auth/addUser", this.form);
        this.loadingLocal = false;
        this.checkuser();
        this.form.username = ""
        this.form.password = ""
        return;
      } catch (err) {
        const { status, data } = err.response;
        if (status === 400) {
          this.error = "Oops, add user failed";
        } else {
          this.error = "Unexpected error:" + JSON.stringify(data);
        }
        this.loadingLocal = false;
        return;
      }
    },
    async deluser($event, id) {
      if (!window.confirm('ユーザーを削除しますか?')) return
      this.deletingUser = id
      await this.$store.dispatch('auth/deleteUser', id)
      this.deletingUser = null
      this.checkuser()
    },
    async updateuser($event, id, jobClass) {
      if (!window.confirm('ユーザーを更新しますか?')) return
      this.updatingUser = id
      console.log(jobClass)
      await this.$store.dispatch('auth/updateUser', { id: id, jobClass: jobClass})
      this.updatingUser = null
      this.checkuser()
    },
    async checkuser() {
      this.loadingLocal = true;
      try {
        const result = await this.$store.dispatch("auth/listUser");
        this.userList = result.data
      } catch (err) {
        console.error(err)
        throw err
      }
      this.loadingLocal = false;
      return;
    },
  },
  mounted() {
    console.log(process.env.VUE_APP_ENDPOINT);
    this.loading = false;
    this.checkuser()
  },
};
</script>
