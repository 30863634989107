<template>
  <div class="flex-container items-center text-black dark:text-white">

    <material-modal
      :material="selectedMaterial"
      :requiredNum="selectedMaterial"
      v-on:close="selectedMaterial = null"
    />
    <div class="m-2">
      <span class="font-bold mr-2">登録対象</span>
      <select 
        class="bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        v-model="this.currentData">
        <option value="基礎エース計画">基礎エース計画</option>
        <option value="ベース計画">ベース計画</option>
        <option value="柱組立計画">柱組立計画</option>
      </select>

    </div>
    <div class="">
      <input
          type="file"
          name="uploader"
          ref="uploader"
          @change="onChangeFile"
      >
      <button
          type="submit"
          class="main ml-3 bg-transparent hover:bg-gray-500 text-gray-700 font-semibold hover:text-white py-2 px-4 border border-gray-500 hover:border-transparent rounded"
          @click="submitFile"
      >
        <loader v-if="loadingLocal"/>
        <div
        v-else
        >登録</div>
      </button>

      <button
          class="ml-3 bg-transparent hover:bg-green-500 text-green-700 font-semibold hover:text-white py-2 px-4 border border-green-500 hover:border-transparent rounded"
          v-on:click="openModel"
      >
        グループ化
      </button>
    </div>
  </div>

  <div
    class="flex-grow"
  >
    <div class="m-4 w-full flex-grow overflow-x-auto overflow-y-auto ">


      <div class="overflow-y-auto w-full">
        <table class="mt-3 absolute text-sm border-collapse border-green-800 w-auto">
          <thead>
          <tr class="border-b-2">
            <th class = "border">加工帳行</th>
            <th class = "border">躯体ページ</th>
            <th class = "border">形状番号</th>
            <th class = "border">径</th>
            <th class = "border">材質</th>
            <th class = "border">切寸法</th>
            <th class = "border">切寸合計本数</th>
            <th class = "border">定尺長さ</th>
            <th class = "border">割数</th>
            <th class = "border">定尺本数</th>
            <th class = "border">定尺重量</th>
            <th class = "border">切寸重量</th>
            <th class = "border">定着板</th>
          </tr>
          </thead>
          <tbody>
            <tr 
              v-for="mate in this.tableData" 
              :key="mate.id" 
              class="border-b"
              >
              <!-- <td>{{ user.id }}</td> -->
              <td class = "border">{{ mate.processing_no }}</td>
              <td class = "border">{{ mate.skelton_page_no }}</td>
              <td class = "border">{{ mate.shape_no }}</td>
              <td class = "border">{{ mate.diameter }}</td>
              <td class = "border">{{ mate.material }}</td>
              <td class = "border">{{ mate.cutting_dimention }}</td>
              <td class = "border">{{ mate.cutting_total_num }}</td>
              <td class = "border">{{ mate.standard_length }}</td>
              <td class = "border">{{ mate.divided_num }}</td>
              <td class = "border">{{ mate.standard_num }}</td>
              <td class = "border">{{ mate.standard_weight }}</td>
              <td class = "border">{{ mate.cutting_weight }}</td>
              <td class = "border">{{ mate.fixing_plate  ? "有" : "無"}}</td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</template>
<script>
import Base from '@/mixins/Base.js'
import CanvasMixin from '@/mixins/Canvas.js'
import MaterialModal from '@/views/Construction/MaterialModal.vue'
import loader from "@/components/Loader.vue"

// 拡大縮小 8/8-9
// 部材の登録、DB 8/10
// 部材のグループ分け 8/11-12
// グループのポイント登録 8/13-14
// *計画書は当日にMの人が登録できるように 8/15, 16は予備日
// マウス位置を中心に拡大するようにできていない
// 画像とテーブルはタブで分けた方が良さそう

export default{
  mixins: [Base, CanvasMixin],
  data() {
    return {
      currentData: '',
      currentUrl: null,
      materialFile: null,
      loadingLocal: false,
      tableData: null,
      intId: null,
      selectedMaterial: null,
    }
  },
  components: {
    loader,
    MaterialModal
  },
  computed: {
    
  },
  watch: {
    currentData: function(){
      this.materialsSelected()
    },
    mode: function (mode) {
      if (mode !== 'edit') {
        // this.$store.dispatch('results/setActiveLabel', null)
        if (mode === 'display') {
          this.$store.dispatch('mouse/setCursor', 'default')
        }
      }
      // this.$store.dispatch('canvas/setScale', this.canvasRatio.x) // reset zoom
      this.draw()
    },
    activeLabel: function () {
      console.log('label change')
      this.draw() // redraw
    },
    resized: function (resized) {
      if (resized) {
        // this.draw()
      }
    },
  },
  async mounted() {
    this.loadingLocal = false
    await this.$store.dispatch('materials/fetch', this.activeTaskId)

    // 登録後すぐ表示したいだけだが、関数が動かないので、暫定
    this.intId = window.setInterval(() => {
      this.onfileuploaded()
    }, 3000)
  },
  beforeUnmount: function () {
    if (this.intId) {
      window.clearInterval(this.intId)
    }
  },
  methods: {
    async openModel() {
      if (this.currentData){
        if (this.tableData){
          // console.log(this.tableData)
          await this.$store.dispatch('materialsgroup/fetch', this.activeTaskId)
          this.selectedMaterial = this.tableData
        }else{
          alert("部材データを登録してください。")
        }
      }else{
        alert("登録対象を選択してください。")
      }
    },
    materialsSelected: function () {
      this.tableData = []
      for (var key in this.materials) {
        var mate = this.materials[key]
        if (mate.parent_data==this.currentData){
          this.tableData.push(mate)
        }
      }
    },
    async checkmaterials() {
      try {
        await this.$store.dispatch("materials/fetch", this.activeTaskId)
      } catch (err) {
        console.error(err)
        throw err
      }
      return;
    },
    async onfileuploaded() {
      if (this.currentData){
        console.log("onfileuploaded")
        this.loadingLocal = false
        await this.$store.dispatch("materials/fetch", this.activeTaskId)

        this.$store.dispatch('tasks/chageStatus', 
                              {id: this.activeTaskId,
                              status: "部材登録"})
        this.materialsSelected()
      }
    },
    onChangeFile: function(e){
      const { target } = e;
      if (!(target instanceof HTMLInputElement )) return;
      if (target.files == null) return;
      console.log(target.files[0])
      this.materialFile = target.files[0]
      this.loadingLocal = false
    },
    submitFile: function(){
      if (this.currentData!=""){
        this.loadingLocal = true
        const xhr = new XMLHttpRequest()
        const formData = new FormData()
        formData.append("action", "material")
        formData.append("type", this.currentData)
        formData.append("file", this.materialFile)
        xhr.open("PATCH", `${process.env.VUE_APP_ENDPOINT}/task/${this.activeTaskId}/`)
        xhr.overrideMimeType('text/plain; charset=x-user-defined-binary')
        xhr.setRequestHeader('Authorization', `Token ${this.token}`)
        xhr.onload = function () {
          this.loadingLocal = false
          if (xhr.status >= 200 && xhr.status < 300) {
            // console.log(xhr.responseText)
            // this.onfileuploaded()
            alert('登録完了!')
          } else {
            alert('登録失敗!')
          }
        }
        xhr.send(formData);
      }else{
        alert("登録対象を選択してください!")
      }
    },
  }
}
</script>
