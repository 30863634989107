<template>
  <div class="w-full flex-grow flex flex-col mx-auto pt-4 pb-4 px-2">
    <div class="grid grid-cols-12">
      <div class="col-start-4 col-span-6 shadow">
        <div class="text-lg font-extrabold">Login</div>
        <div
          v-if="error !== ''"
          class="bg-red-200 relative text-red-500 m-3 py-3 px-3 rounded-lg"
          dismissible
          variant="danger"
        >
          <font-awesome-icon
            class="mx-2"
            icon="exclamation"
          />
          {{ error }}
        </div>
        <div class="text-left p-4" disabled="false">
          <label for="username">Email</label>
          <input
            type="text"
            v-model="form.username"
            required="required"
            class="w-full block border bg-white border-gray-700 mb-2 p-1"/>
          <label for="password">Password</label>
          <input
            type="password"
            v-model="form.password"
            required="required"
            class="w-full block border bg-white border-gray-600 mb-2 p-1"/>
          <button
            type="submit"
            class="w-full mt-3 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            v-on:click="login">
            <loader v-if="loadingLocal" />
            <div 
              v-else
            >Submit</div>
          </button>
        </div>
      </div>
    </div>
  </div>
  
</template>
<style scoped>
.login h1 {
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  letter-spacing: 1px;
  text-align: center;
}
input {
  width: 100%;
  margin-bottom: 10px;
  border: none;
  outline: none;
  padding: 10px;
  font-size: 13px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}
/* input:focus {
  box-shadow: inset 0 -5px 45px rgba(100, 100, 100, 0.4),
    0 1px 1px rgba(255, 255, 255, 0.2);
} */
.spinner-border {
  vertical-align: middle !important;
}
</style>
<script>
import loader from "@/components/Loader.vue"

export default {
  data() {
    return {
      loadingLocal: false,
      error: "",
      form: {
        // username: "mclass@itoishoukai.com",
        // password: "mclass",
        username: "",
        password: "",
      },
    };
  },
  components:{
    loader,
  },
  methods: {
    async login() {
      this.loadingLocal = true;
      try {
        console.log(this.form)
        await this.$store.dispatch("auth/getToken", this.form);
      } catch (err) {
        const { status, data } = err.response;
        if (status === 400) {
          this.error = "Oops, login failed";
        } else {
          this.error = "Unexpected error:" + JSON.stringify(data);
        }
        this.loadingLocal = false;
        return;
      }
      // try {
      //   await this.$store.dispatch("tasks/fetch");
      // } catch (err) {
      //   const { data } = err.response;
      //   if (data) this.error = JSON.stringify(data);
      //   return;
      // }
      this.$router.push({ name: "Tasks" });
    },
  },
  mounted() {
    // console.log(process.env.VUE_APP_ENDPOINT)
    this.$store.dispatch("auth/destroy")
    this.loading = false
    // console.log(this.$store.getters['construction/isMainView'])
  },
};
</script>
