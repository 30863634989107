<template>
    <aside 
      class="relative bg-sidebar h-full w-full sm:block shadow-xl" 
      :style="{'cursor': cursor}"
    >
        <div class="p-6">
            <span 
                v-on:click="changeView(0)"
                class="ml-2 tracking-wide truncate text-black text-2xl font-semibold cursor-pointer"
            >業務リスト</span>
        </div>
        <nav class="text-black text-base font-semibold pt-3">
            <a 
                v-on:click="changeView(0)"
                class="flex items-center text-black py-4 pl-6 nav-item cursor-pointer hover:bg-gray-300 pr-6"
            >
                <span class="inline-flex justify-center items-center ml-4">
                  <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                </span>
                <span class="ml-2 text-sm tracking-wide truncate">全体</span>
            </a>
            <a 
            v-if="userInfo.job_class.match(/M/)"
                v-on:click="changeView(1)"
                class="flex items-center text-black py-4 pl-6 nav-item cursor-pointer hover:bg-gray-300 pr-6"
            >
                <span class="inline-flex justify-center items-center ml-4">
                <svg class="w-5 h-5" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.63 22.75">
                  <polyline class="st0" points="16.22,9.53 16.22,8.08 29.44,8.08 29.44,22.56 1.5,22.56 1.5,9.28 1.5,1.5 10.31,1.5 10.31,2.89 "/>
                  <line class="st0" x1="5.26" y1="9.28" x2="1.5" y2="9.28"/>
                  <line class="st0" x1="5.26" y1="17.25" x2="1.5" y2="17.25"/>
                  <line class="st0" x1="10.19" y1="13.87" x2="10.19" y2="22.56"/>
                  <path class="st0" d="M16.16,13.87"/>
                  <line class="st0" x1="20.51" y1="18.87" x2="20.51" y2="22.56"/>
                  <line class="st0" x1="25.06" y1="15.8" x2="29.44" y2="15.8"/>
                  <line class="st0" x1="14.31" y1="15.8" x2="10.19" y2="15.8"/>
                </svg>
                </span>
                <span class="ml-2 text-sm tracking-wide truncate">図面登録</span>
                <span class="hidden md:block px-2 py-0.5 ml-auto text-xs font-medium tracking-wide text-red-500 bg-red-50 rounded-full">{{numPdfFile}}/2</span>
            </a>
            <a 
            v-if="userInfo.job_class.match(/M/)"
                v-on:click="changeView(2)"
                class="flex items-center text-black py-4 pl-6 nav-item cursor-pointer hover:bg-gray-300 pr-6"
            >
                <span class="inline-flex justify-center items-center ml-4">
                  <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 29.12 28.36" xmlns="http://www.w3.org/2000/svg">
                    <circle class="st0" cx="5.18" cy="23.18" r="3.68"/>
                    <circle class="st0" cx="15.38" cy="23.18" r="3.68"/>
                    <line class="st1" x1="2.7" y1="20.3" x2="17.1" y2="1.5"/>
                    <path class="st0" d="M11.92,5.65"/>
                    <path class="st0" d="M14.01,15.6c0,2.03-1.65,3.68-3.68,3.68s-3.68-1.65-3.68-3.68c0-0.88,0.31-1.68,0.82-2.31
                      c0.67-0.83,1.7-1.36,2.86-1.36C12.36,11.92,14.01,13.57,14.01,15.6z"/>
                    <line class="st1" x1="18.53" y1="25" x2="27.62" y2="13.13"/>
                    <line class="st1" x1="14.01" y1="18.94" x2="26.38" y2="2.79"/>
                    <line class="st1" x1="7.36" y1="13.13" x2="17.1" y2="1.5"/>
                  </svg>
                </span>
                <span class="ml-2 text-sm tracking-wide truncate">部材登録</span>
                <span class="hidden md:block px-2 py-0.5 ml-auto text-xs font-medium tracking-wide text-red-500 bg-red-50 rounded-full">{{countMaterialRegi}}/3</span>
            </a>
            <a
            v-if="userInfo.job_class.match(/M/)"
                v-on:click="changeView(6)"
                class="flex items-center text-black py-4 pl-6 nav-item cursor-pointer hover:bg-gray-300 pr-6"
            >
                  <span class="inline-flex justify-center items-center ml-4">
                  <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                    <g>
                      <path class="st0" d="M15,11.6c-1.9,0-3.4,1.5-3.4,3.4c0,1.9,1.5,3.4,3.4,3.4c1.9,0,3.4-1.5,3.4-3.4C18.4,13.1,16.9,11.6,15,11.6z"
                        />
                      <path class="st0" d="M29.9,14.9L24,13.4c-0.3-1.9-1.2-3.6-2.5-4.9c-1.3-1.3-3-2.2-4.9-2.5l-1.6-5.9C15.1,0,15,0,15,0
                        c0,0-0.1,0-0.1,0.1L13.4,6c-1.9,0.3-3.6,1.2-4.9,2.5c-1.3,1.3-2.2,3-2.5,4.9l-5.9,1.6C0,14.9,0,15,0,15c0,0,0,0.1,0.1,0.1L6,16.6
                        c0.3,1.9,1.2,3.6,2.5,4.9c1.3,1.3,3,2.2,4.9,2.5l1.6,5.9c0,0,0,0.1,0.1,0.1c0,0,0.1,0,0.1-0.1l1.6-5.9c1.9-0.3,3.6-1.2,4.9-2.5
                        c1.3-1.3,2.2-3,2.5-4.9l5.9-1.6C30,15.1,30,15,29.9,14.9C30,15,30,14.9,29.9,14.9z M19.8,19.8c-1.2,1.2-2.9,2-4.8,2
                        c-1.9,0-3.6-0.8-4.8-2c-1.2-1.2-2-2.9-2-4.8c0-1.9,0.8-3.6,2-4.8c1.2-1.2,2.9-2,4.8-2c1.9,0,3.6,0.8,4.8,2c1.2,1.2,2,2.9,2,4.8
                        C21.8,16.9,21,18.6,19.8,19.8z"/>
                    </g>
                    </svg>
                  </span>
              <span class="ml-2 text-sm tracking-wide truncate">工事位置登録</span>
              <span class="hidden md:block px-2 py-0.5 ml-auto text-xs font-medium tracking-wide text-red-500 bg-red-50 rounded-full">{{countMateriaPointlRegi}}/3</span>
            </a>
            <a 
                v-on:click="changeView(3)"
                class="flex items-center text-black py-4 pl-6 nav-item cursor-pointer hover:bg-gray-300 pr-6"
            >
                <span class="inline-flex justify-center items-center ml-4">
                  <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 29.12 28.36" xmlns="http://www.w3.org/2000/svg">
                    <path class="st0" d="M25.93,6.66h-11.1"/>
                    <path class="st0" d="M25.93,15.17h-11.1"/>
                    <path class="st0" d="M25.93,23.69h-11.1"/>
                    <polyline class="st0" points="2.01,6.66 4.49,9.46 10.95,3.64 "/>
                    <polyline class="st0" points="2.81,14.29 5.29,17.09 11.75,11.27 "/>
                    <polyline class="st0" points="2.81,22.5 5.29,25.3 11.75,19.48 "/>
                  </svg>
                </span>
                <span class="ml-2 text-sm tracking-wide truncate">基礎エース計画書</span>
                <span class="hidden md:block px-2 py-0.5 ml-auto text-xs font-medium tracking-wide text-red-500 bg-red-50 rounded-full">{{percentBaseAce}}%</span>
            </a>
            <a 
                v-on:click="changeView(5)"
                class="flex items-center text-black py-4 pl-6 nav-item cursor-pointer hover:bg-gray-300 pr-6"
            >
                <span class="inline-flex justify-center items-center ml-4">
                  <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 29.12 28.36" xmlns="http://www.w3.org/2000/svg">
                    <path class="st0" d="M25.93,6.66h-11.1"/>
                    <path class="st0" d="M25.93,15.17h-11.1"/>
                    <path class="st0" d="M25.93,23.69h-11.1"/>
                    <polyline class="st0" points="2.01,6.66 4.49,9.46 10.95,3.64 "/>
                    <polyline class="st0" points="2.81,14.29 5.29,17.09 11.75,11.27 "/>
                    <polyline class="st0" points="2.81,22.5 5.29,25.3 11.75,19.48 "/>
                  </svg>
                </span>
                <span class="ml-2 text-sm tracking-wide truncate">ベース計画書</span>
                <span class="hidden md:block px-2 py-0.5 ml-auto text-xs font-medium tracking-wide text-red-500 bg-red-50 rounded-full">{{percentBase}}%</span>
            </a>
            <a 
                v-on:click="changeView(4)"
                class="flex items-center text-black py-4 pl-6 nav-item cursor-pointer hover:bg-gray-300 pr-6"
            >
                <span class="inline-flex justify-center items-center ml-4">
                  <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 29.12 28.36" xmlns="http://www.w3.org/2000/svg">
                    <path class="st0" d="M25.93,6.66h-11.1"/>
                    <path class="st0" d="M25.93,15.17h-11.1"/>
                    <path class="st0" d="M25.93,23.69h-11.1"/>
                    <polyline class="st0" points="2.01,6.66 4.49,9.46 10.95,3.64 "/>
                    <polyline class="st0" points="2.81,14.29 5.29,17.09 11.75,11.27 "/>
                    <polyline class="st0" points="2.81,22.5 5.29,25.3 11.75,19.48 "/>
                  </svg>
                </span>
                <span class="ml-2 text-sm tracking-wide truncate">柱組立計画書</span>
                <span class="hidden md:block px-2 py-0.5 ml-auto text-xs font-medium tracking-wide text-red-500 bg-red-50 rounded-full">{{percentPillar}}%</span>
            </a>
        </nav>

        <a 
            :disabled="deletingTask === taskid"
            v-on:click="deletetask($event, taskid)"
            class="absolute w-full bottom-0 active-nav-link text-black flex items-center justify-center py-4 cursor-pointer"
            v-if="userInfo.job_class.match(/M/)">
            <font-awesome-icon icon="trash"/>
            削除
        </a>
    </aside>
</template>

<style scoped>

</style>

<script>
import Base from '@/mixins/Base.js'
export default{
  components: {
  },
  mixins: [Base],
  computed: {
    taskid: function () {
      return this.activeTaskId
    },
    numPdfFile: function () {
      let count = 0
      if (this.activeTask.base_filename) {count++}
      if (this.activeTask.pillar_filename) {count++}
      return count
    },
    countMaterialRegi: function(){
      let count1 = 0
      let count2 = 0
      let count3 = 0
      for (var key in this.materials) {
          var mate = this.materials[key]
          if (mate.parent_data=="基礎エース計画"){
              if (count1==0) {count1+=1}
          }
          if (mate.parent_data=="ベース計画"){
              if (count2==0) {count2+=1}
          }
          if (mate.parent_data=="柱組立計画"){
              if (count3==0) {count3+=1}
          }
      }
      return count1+count2+count3
    },
    countMateriaPointlRegi: function(){
      let count1 = 0
      let count2 = 0
      let count3 = 0
      for (var key in this.materialsgrouppoint) {
          var mate = this.materialsgrouppoint[key]
          if (mate.parent_data=="基礎エース計画"){
              if (count1==0) {count1+=1}
          }
          if (mate.parent_data=="ベース計画"){
              if (count2==0) {count2+=1}
          }
          if (mate.parent_data=="柱組立計画"){
              if (count3==0) {count3+=1}
          }
      }
      return count1+count2+count3
    },
    percentBaseAce: function(){
      let count = 0
      let sum = 0
      for (var key in this.materialsgrouppoint) {
          var mate = this.materialsgrouppoint[key]
          if (mate.parent_data=="基礎エース計画"){
              sum += 1
              if (mate.done){count+=1}
          }
      }
      return sum ? Math.round(count/sum*100) : 0
    },
    percentBase: function(){
      let count = 0
      let sum = 0
      for (var key in this.materialsgrouppoint) {
          var mate = this.materialsgrouppoint[key]
          if (mate.parent_data=="ベース計画"){
              sum += 1
              if (mate.done){count+=1}
          }
      }
      return sum ? Math.round(count/sum*100) : 0
    },
    percentPillar: function(){
      let count = 0
      let sum = 0
      for (var key in this.materialsgrouppoint) {
          var mate = this.materialsgrouppoint[key]
          if (mate.parent_data=="柱組立計画"){
              sum += 1
              if (mate.done){count+=1}
          }
      }
      return sum ? Math.round(count/sum*100) : 0
    }
  },
  data() {
    return {
      loadingLocal: false,
      error: "",
      form: {
        taskname: "",
      },
      onoff: {
        "main_view": false,
        "file_view": false,
        "material_view": false,
        "baseace_view": false,
        "pillar_view": false,
        "base_view": false,
        "point_view": false,
      },
      selectedTask: null,
      deletingTask: null,
      requestingTask: null,
      intId: null,
    };
  },
  methods: {
    changeView: function(id){
      this.onoff.main_view = (id==0)
      this.onoff.file_view = (id==1)
      this.onoff.material_view = (id==2)
      this.onoff.baseace_view = (id==3)
      this.onoff.pillar_view = (id==4)
      this.onoff.base_view = (id==5)
      this.onoff.point_view = (id==6)
      if (id==2){this.$store.dispatch('canvas/setImage', null)}
      this.$store.dispatch('construction/onoffView', this.onoff)
    },
    deletetask: async function ($event, id) {
        if (!window.confirm('この工事データを削除しますか？')) return
        this.deletingTask = id
        await this.$store.dispatch('tasks/delete', this.activeTaskId)
        this.deletingTask = null
        this.$router.push({name:"Tasks"})
    },
  }
}
</script>
