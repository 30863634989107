<template>
  <construction-main>
    <template v-slot:top>
      {{constructionName}}
    </template>

    <template v-slot:menu>
      <flow-panel/>
    </template>
    <template v-slot:main>
      <main-panel v-if="mainView"/>
      <file-panel v-if="fileView"/>
      <material-panel v-if="materialView"/>
      <plan-panel v-if="baseAceView" targetPlan="基礎エース計画"/>
      <plan-panel v-if="baseView" targetPlan="ベース計画"/>
      <plan-panel v-if="pillarView" targetPlan="柱組立計画"/>
      <construction-point v-if="pointView"/>
    </template>

  </construction-main>

</template>
<script>
import ConstructionMain from '@/layouts/ConstructionMain.vue'
// import loader from "@/components/Loader.vue"
import Base from '@/mixins/Base.js'
import FlowPanel from '../views/Construction/FlowPanel.vue'
import MainPanel from '../views/Construction/MainPanel.vue'
import FilePanel from '../views/Construction/FilePanel.vue'
import MaterialPanel from '../views/Construction/MaterialPanel.vue'
import PlanPanel from '../views/Construction/PlanPanel.vue'
import ConstructionPoint from "../views/Construction/ConstructionPoint"

export default{
    mixins: [Base],
    data(){
        return{

        }
    },
    computed: {
      constructionName: function(){
        // console.log(this.activeTask)
        if (this.activeTask.task_name!=null){
          return this.activeTask.task_name
        }else{
          return ''
        }
      }
    },
    components: {
      ConstructionPoint,
      ConstructionMain,
      FlowPanel,
      MainPanel,
      FilePanel,
      MaterialPanel,
      PlanPanel,
    },
    async mounted() {
      await this.$store.dispatch('materials/fetch', this.activeTaskId)
      await this.$store.dispatch('materialsgrouppoint/fetch', this.activeTaskId)
    },
    async created() {

    }

}
</script>

