<template>
<div class="w-full flex-grow p-3">
    <div class="text-sm font-light tracking-wide text-gray-400 uppercase">図面登録</div>
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 p-4 gap-4">
        <div class="bg-blue-500 dark:bg-gray-800 shadow-lg rounded-md flex items-center justify-between p-3 border-b-4 border-blue-600 dark:border-gray-600 text-white font-medium group">
            <div class="text-left">
                <p 
                    class="text-2xl text-green-400 font-bold"
                    v-if="this.activeTask.base_filename"
                >登録済み</p>
                <p 
                    class="text-2xl text-red-400 font-bold"
                    v-else
                >未登録</p>
                <p>基礎エース</p>
            </div>
        </div>
        <div class="bg-blue-500 dark:bg-gray-800 shadow-lg rounded-md flex items-center justify-between p-3 border-b-4 border-blue-600 dark:border-gray-600 text-white font-medium group">
            <div class="text-left">
                <p 
                    class="text-2xl text-green-400 font-bold"
                    v-if="this.activeTask.pillar_filename"
                >登録済み</p>
                <p 
                    class="text-2xl text-red-400 font-bold"
                    v-else
                >未登録</p>
                <p>柱組立</p>
            </div>
        </div>
    </div>
    <div class="text-sm font-light tracking-wide text-gray-400 uppercase pt-3">部材登録</div>
    <div class="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 p-4 gap-4">
        <div class="bg-blue-500 dark:bg-gray-800 shadow-lg rounded-md flex items-center justify-between p-3 border-b-4 border-blue-600 dark:border-gray-600 text-white font-medium group">
            <div class="text-left">
                <p class="text-2xl">{{aceCount}}件</p>
                <p>基礎エース計画</p>
            </div>
        </div>
        <div class="bg-blue-500 dark:bg-gray-800 shadow-lg rounded-md flex items-center justify-between p-3 border-b-4 border-blue-600 dark:border-gray-600 text-white font-medium group">     
            <div class="text-left">
                <p class="text-2xl">{{baseCount}}件</p>
                <p>ベース計画</p>
            </div>
        </div>
        <div class="bg-blue-500 dark:bg-gray-800 shadow-lg rounded-md flex items-center justify-between p-3 border-b-4 border-blue-600 dark:border-gray-600 text-white font-medium group">     
            <div class="text-left">
                <p class="text-2xl">{{pillarCount}}件</p>
                <p>柱組立計画</p>
            </div>
        </div>
    </div>

    <div class="text-sm font-light tracking-wide text-gray-400 uppercase pt-3">工事位置登録</div>
    <div class="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 p-4 gap-4">
        <div class="bg-blue-500 dark:bg-gray-800 shadow-lg rounded-md flex items-center justify-between p-3 border-b-4 border-blue-600 dark:border-gray-600 text-white font-medium group">
            <div class="text-left">
                <p class="text-2xl">{{acePointCount}}箇所</p>
                <p>基礎エース計画</p>
            </div>
        </div>
        <div class="bg-blue-500 dark:bg-gray-800 shadow-lg rounded-md flex items-center justify-between p-3 border-b-4 border-blue-600 dark:border-gray-600 text-white font-medium group">     
            <div class="text-left">
                <p class="text-2xl">{{basePointCount}}箇所</p>
                <p>ベース計画</p>
            </div>
        </div>
        <div class="bg-blue-500 dark:bg-gray-800 shadow-lg rounded-md flex items-center justify-between p-3 border-b-4 border-blue-600 dark:border-gray-600 text-white font-medium group">     
            <div class="text-left">
                <p class="text-2xl">{{pillarPointCount}}箇所</p>
                <p>柱組立計画</p>
            </div>
        </div>
    </div>
    <div class="text-sm font-light tracking-wide text-gray-400 uppercase pt-3">工事進捗</div>
    <div class="grid grid-cols-1 lg:grid-cols-1 p-4 gap-4">
        <div class="relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-gray-50 dark:bg-gray-800 w-full shadow-lg rounded">
            <div class="rounded-t mb-0 px-0 border-0">
                <div class="block w-full overflow-x-auto">
                    <table class="items-center w-full bg-transparent border-collapse">
                        <thead>
                        <tr>
                            <th class="px-4 bg-gray-100 dark:bg-gray-600 text-gray-500 dark:text-gray-100 align-middle border border-solid border-gray-200 dark:border-gray-500 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"></th>
                            <th class="px-4 bg-gray-100 dark:bg-gray-600 text-gray-500 dark:text-gray-100 align-middle border border-solid border-gray-200 dark:border-gray-500 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">件数</th>
                            <th class="px-4 bg-gray-100 dark:bg-gray-600 text-gray-500 dark:text-gray-100 align-middle border border-solid border-gray-200 dark:border-gray-500 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left min-w-140-px">進捗率</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr class="text-gray-700 dark:text-gray-100">
                            <th class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">All</th>
                            <td class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{{pillarPointCount+acePointCount+basePointCount}}件</td>
                            <td class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                            <div class="flex items-center">
                                <span class="mr-2">{{percentAll}}%</span>
                                <div class="relative w-full">
                                <div class="overflow-hidden h-2 text-xs flex rounded bg-blue-200">
                                    <div 
                                        :style="{width: percentAll + '%'}"
                                        class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-600"></div>
                                </div>
                                </div>
                            </div>
                            </td>
                        </tr>
                        <tr class="text-gray-700 dark:text-gray-100">
                            <th class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">基礎エース</th>
                            <td class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{{acePointCount}}件</td>
                            <td class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                            <div class="flex items-center">
                                <span class="mr-2">{{percentBaseAce}}%</span>
                                <div class="relative w-full">
                                <div class="overflow-hidden h-2 text-xs flex rounded bg-green-200">
                                    <div 
                                        :style="{width: percentBaseAce + '%'}"
                                        class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"></div>
                                </div>
                                </div>
                            </div>
                            </td>
                        </tr>
                        <tr class="text-gray-700 dark:text-gray-100">
                            <th class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">ベース</th>
                            <td class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{{basePointCount}}件</td>
                            <td class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                            <div class="flex items-center">
                                <span class="mr-2">{{percentBase}}%</span>
                                <div class="relative w-full">
                                <div class="overflow-hidden h-2 text-xs flex rounded bg-purple-200">
                                    <div 
                                        :style="{width: percentBase + '%'}"
                                        class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple-500"></div>
                                </div>
                                </div>
                            </div>
                            </td>
                        </tr>
                        <tr class="text-gray-700 dark:text-gray-100">
                        <th class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">柱組立</th>
                        <td class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{{pillarPointCount}}件</td>
                        <td class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                            <div class="flex items-center">
                            <span class="mr-2">{{percentPillar}}%</span>
                            <div class="relative w-full">
                                <div class="overflow-hidden h-2 text-xs flex rounded bg-red-200">
                                <div 
                                    :style="{width: percentPillar + '%'}"
                                    class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500"
                                ></div>
                                </div>
                            </div>
                            </div>
                        </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
</template>


<script>
import Base from '@/mixins/Base.js'

export default{
    mixins: [Base],
    data(){
        return{

        }
    },
    computed: {
      constructionName: function(){
        if (this.activeTask.task_name){
          return this.activeTask.task_name
        }else{
          return ''
        }
      },
      pillarCount: function(){
        var count = 0

        for (var key in this.materials) {
            var mate = this.materials[key]
            if (mate.parent_data=="柱組立計画"){
                count+=1
            }
        }
        return count
      },
      aceCount: function(){
        var count = 0

        for (var key in this.materials) {
            var mate = this.materials[key]
            if (mate.parent_data=="基礎エース計画"){
                count+=1
            }
        }
        return count
      },
      baseCount: function(){
        var count = 0

        for (var key in this.materials) {
            var mate = this.materials[key]
            if (mate.parent_data=="ベース計画"){
                count+=1
            }
        }
        return count
      },
      pillarPointCount: function(){
        var count = 0

        for (var key in this.materialsgrouppoint) {
            var mate = this.materialsgrouppoint[key]
            if (mate.parent_data=="柱組立計画"){
                count+=1
            }
        }
        return count
      },
      acePointCount: function(){
        var count = 0

        for (var key in this.materialsgrouppoint) {
            var mate = this.materialsgrouppoint[key]
            if (mate.parent_data=="基礎エース計画"){
                count+=1
            }
        }
        return count
      },
      basePointCount: function(){
        var count = 0

        for (var key in this.materialsgrouppoint) {
            var mate = this.materialsgrouppoint[key]
            if (mate.parent_data=="ベース計画"){
                count+=1
            }
        }
        return count
      },
        percentAll: function(){
            let count = 0
            let sum = 0
            for (var key in this.materialsgrouppoint) {
                var mate = this.materialsgrouppoint[key]
                sum += 1
                if (mate.done){count+=1}
            }
            if (count>0){
                this.$store.dispatch('tasks/chageStatus', 
                                    {id: this.activeTaskId,
                                    status: "工事中"})
                if(count==sum){
                    this.$store.dispatch('tasks/chageStatus', 
                                        {id: this.activeTaskId,
                                        status: "工事完了"})
                }
            }
            return sum ? Math.round(count/sum*100) : 0
        },
        percentBaseAce: function(){
            let count = 0
            let sum = 0
            for (var key in this.materialsgrouppoint) {
                var mate = this.materialsgrouppoint[key]
                if (mate.parent_data=="基礎エース計画"){
                    sum += 1
                    if (mate.done){count+=1}
                }
            }
            return sum ? Math.round(count/sum*100) : 0
        },
        percentBase: function(){
            let count = 0
            let sum = 0
            for (var key in this.materialsgrouppoint) {
                var mate = this.materialsgrouppoint[key]
                if (mate.parent_data=="ベース計画"){
                    sum += 1
                    if (mate.done){count+=1}
                }
            }
            return sum ? Math.round(count/sum*100) : 0
        },
        percentPillar: function(){
            let count = 0
            let sum = 0
            for (var key in this.materialsgrouppoint) {
                var mate = this.materialsgrouppoint[key]
                if (mate.parent_data=="柱組立計画"){
                    sum += 1
                    if (mate.done){count+=1}
                }
            }
            return sum ? Math.round(count/sum*100) : 0
        }
    },
    components: {
        
    }
}
</script>

