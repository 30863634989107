<template>
    <div class="text-9xl">{{this.htmlContent}}</div>
    
    <div class="">
        <img class="object-contain w-full h-1/2" v-bind:src="image_path"/>
    </div>
</template>

<script>
export default {
  data() {
    return {
      htmlContent: "",
      image_path: ""
    }
  },
  computed:{
    
  },
  methods:{
    loadHtml() {
      this.setImagePath()
    //   console.log(this.image_path)
      fetch("./out.txt").then((res) => {
        res.text().then((html) => {
          this.htmlContent = html
          console.log(html)
        })
      })
    },
    setImagePath: function () {
        // console.log(Math.random().toString(32).substring(2))
        this.image_path = "./out.png?" + Math.random().toString(32).substring(2)
        // return "out.png?1234"
    },
  },
  mounted() {
    this.loadHtml()

    this.intId = window.setInterval(() => {
      this.loadHtml()
    }, 5000)
  },
}
</script>
