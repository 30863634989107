<template>
  <div 
    style="height: calc(100% - 56px)"
    class="relative flex w-screen bg-white dark:bg-gray-600">
    <div class="h-full w-64 border-l border-blue dark:border-white">
      <slot name="menu"></slot>
    </div>
    <div class="w-full flex flex-col h-full overflow-y-hidden">
      <div class="text-3xl w-full items-center font-bold text-center border-b border-blue dark:border-white">
        <slot name="top"></slot>
      </div>
      <div class="w-full overflow-x-hidden border-t flex flex-col h-full">
        <slot name="main"></slot>
      </div>
    </div>
    <slot name="popup"></slot>
  </div>
</template>
<script>
</script>