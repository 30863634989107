<template>
<div 
  class="block"
  v-if="this.contextmenu"
  style="position:absolute" 
  :style="{ top: absoMouY-60+'px',  left: absoMouX-10+'px' }"
  > 
  <loader />
</div>
<div 
  class='border border-black-500 rounded shadow-lg p-1 bg-gray-100 text-black-500 text-left'
  style="position:absolute; z-index:9999" 
  :style="{ top: '36px',  left: '250px' ,'cursor': cursor}"
>
  <ul class="p-1">
    <li
      v-for="(g, i) in this.groupList"
      :key="i+'infoGroup'"
    >
      <span :style="{color: LINECOLORS[i]}">●</span>
      {{ g }}
    </li>
  </ul>
</div>
  <div
    style="height: calc(100% - 250px);"
    class="flex w-full justify-end"  
    :style="{ 'cursor': cursor}"
  >
    <div
      ref="wrapper"
      style="width: calc(100% - 250px)"
      class="h-full"
    >
      <canvas
          ref="canvas"
          class="mx-auto"
          :top=0
          :left=0
          :height="canvasSize.h"
          :width="canvasSize.w"
          v-on:dblclick="fitWindow"
          v-on:mouseup="myUp"
          v-on:mousedown="myDown"
          v-on:mousemove="myMove"
          v-on:mousewheel="onWheel"
          v-on:touchstart="myDown2"
          v-on:touchend="myUp"
          v-on:touchmove="myMove2"
      >
      </canvas>
    </div>
    <div
      style="width: 250px;"
      class="h-full border-l border-light-blue-400 overflow-y-scroll"
    >
      <label class = "ml-2" for="setectedGroup">日付</label>
      <select
        class="mr-3 mt-3 bg-gray-200 border border-gray-200 text-gray-700 py-2 px-2 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        v-model="setectedDate"
      >
        <option
          value="ALL"
        >
          ALL
        </option>
        <option
          v-for="(g, i) in this.getDateList"
          :key="i"
          :value="g"
        >
          {{ g }}
        </option>
      </select>

      <div class="w-full">
        <table class="mt-3 w-full text-sm border-collapse border-green-800">
          <thead>
          <tr class="border-b-2">
            <th class = "border">グループ</th>
            <th class = "border">x</th>
            <th class = "border">y</th>
            <th class = "border">実行</th>
            <th class = "border">確認</th>
          </tr>
          </thead>
          <tbody>
            <tr 
              v-for="mgp in this.activePointList" 
              :key="mgp.x + ',' + mgp.y" 
              class="border-b"
              >
              <td class = "border">{{ mgp.group_name }}</td>
              <td class = "border">{{ mgp.x }}</td>
              <td class = "border">{{ mgp.y }}</td>
              <td class = "border">{{ mgp.done ? "完了" : "未" }}</td>
              <td class = "border">{{ mgp.checked ? "済" : "未"  }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div
   style="height:250px"
   class="w-full border-t border-light-blue-400"  
  >
    <div
      class="h-full overflow-y-scroll"
    >
      <div>
        <select
          class="mr-3 mt-3 bg-white-200 border border-gray-200 text-gray-700 py-2 px-2 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          v-model="setectedMaterial"
        >
          <option value="本日必要な部材">本日必要な部材</option>
          <option
            v-for="(g, i) in groupList"
            :key="i"
            :value="g"
          >
            {{ g }}
          </option>
        </select>
        <button
            class="ml-3 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded"
            @click="fitWindow"
        >
          フィット
        </button>
      </div>
      
      <div class="w-full">
        <table class="mt-2 relative text-sm border-collapse border-green-800 w-full">
          <thead>
          <tr class="border-b-2">
            <th class = "border">加工帳行</th>
            <th class = "border">躯体ページ</th>
            <th class = "border">形状番号</th>
            <th class = "border">径</th>
            <th class = "border">材質</th>
            <th class = "border">切寸法</th>
            <th class = "border">切寸合計本数</th>
            <th class = "border">定尺長さ</th>
            <th class = "border">割数</th>
            <th class = "border">定尺本数</th>
            <th class = "border">定尺重量</th>
            <th class = "border">切寸重量</th>
            <th class = "border">定着板</th>
            <th class = "border">必要数</th>
          </tr>
          </thead>
          <tbody>
            <tr 
              v-for="mate in this.materialData" 
              :key="mate[0]" 
              class="border-b"
              >
              <!-- <td>{{ user.id }}</td> -->
              <td class = "border">{{ mate[0] }}</td>
              <td class = "border">{{ mate[1] }}</td>
              <td class = "border">{{ mate[2] }}</td>
              <td class = "border">{{ mate[3] }}</td>
              <td class = "border">{{ mate[4] }}</td>
              <td class = "border">{{ mate[5] }}</td>
              <td class = "border">{{ mate[6] }}</td>
              <td class = "border">{{ mate[7] }}</td>
              <td class = "border">{{ mate[8] }}</td>
              <td class = "border">{{ mate[9] }}</td>
              <td class = "border">{{ mate[10] }}</td>
              <td class = "border">{{ mate[11] }}</td>
              <td class = "border">{{ mate[12]  ? "有" : "無"}}</td>
              <td class = "border">{{ mate[13] }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    

  </div>
</template>

<script>
import Base from '@/mixins/Base.js'
import CanvasMixin from '@/mixins/Canvas.js'
import moment from 'moment'

import loader from "@/components/Loader.vue"
// タブレット操作可能にする
// 必要部材のリスト

export default{
  mixins: [Base, CanvasMixin],
  components:{
    loader,
  },
  data(){
    return {
      date: new Date(),
      setectedDate: null,
      setectedMaterial: '本日必要な部材',
      materialData: [],
      LINECOLORS: [
        'aqua', //aqua
        'red', // red
        'darkviolet', // purple
        'green', //green
        'fuchsia', // fuchsia
      ],
    }
  },
  watch: {
    setectedDate: function(){
      console.log("setectedDate")
      this.$store.dispatch('materialsgrouppoint/setActivePointList', this.getPointList())
      if (this.setectedMaterial=="本日必要な部材"){
        this.getMateriarlList()
      }
      this.draw(false)
    },
    setectedMaterial: function(){
      if (this.setectedMaterial=="本日必要な部材"){
        this.getMateriarlList()
      }else{
        this.getMateriarlGroupList()
      }
    },
    scale: function () {
      if (this.img !== null) {
        this.draw()
        this.$store.dispatch('canvas/setPrevScale', this.scale)
        this.$store.dispatch('canvas/setToolTip', false)
        this.$store.dispatch('canvas/setContextMenu', false)
      }
    },
  },
  computed: {
    getDateList(){

      let dates = []
      
      for (let i=0; i<this.materialsgrouppoint.length; i++){
        let mgp = this.materialsgrouppoint[i]
        if (mgp.parent_data==this.activePlan){
          if (mgp['exec_date']){
            dates.push(this.transStrDate(mgp['exec_date']))
          }
        }
      }
      
      return Array.from(new Set(dates)).sort(function(a, b){return (a > b ? 1 : -1)})
    },
    
  },
  async mounted() {
    const cont = this.$refs.wrapper
    const contH = cont.clientHeight
    const contW = cont.clientWidth
    this.$store.dispatch('mouse/setCursor', 'wait')

    this.$store.dispatch('tasks/setActivePlan', this.targetPlan)
    if (this.targetPlan == '基礎エース計画') {
      this.currentUrl = this.activeTask.base_url
      // ベースと柱は同じ図面を使う
    } else if (this.targetPlan == 'ベース計画') {
      this.currentUrl = this.activeTask.pillar_url
    } else if (this.targetPlan == '柱組立計画') {
      this.currentUrl = this.activeTask.pillar_url
    }
    
    this.loadingLocal = false
    // window.addEventListener('resize', this.handleResize)
    await this.$store.dispatch('materials/fetch', this.activeTaskId)
    await this.$store.dispatch('materialsgroup/fetch', this.activeTaskId)
    await this.$store.dispatch('materialsgrouppoint/fetch', this.activeTaskId)
    
    this.$store.dispatch('mouse/setMode', 'plan')

    // canvasの設定
    this.$store.dispatch('canvas/setImage', await this.addImageProcess(this.getFullUrl(this.currentUrl)))
    // this.$store.dispatch('canvas/setImage', null)
    if (contH > 0) {
      this.$store.dispatch('canvas/setSize', {h: contH, w: contW})
      this.$store.dispatch('mouse/setLastImgPosition', { x: 0, y: 0 })
    }
    this.$store.dispatch('canvas/setContext', this.$refs.canvas.getContext('2d'))
    this.$store.dispatch('canvas/setScale', this.canvasRatio.x)
    this.$store.dispatch('mouse/setLastImgPosition', { x: 0, y: 0 } )
    // this.context.img = null
    this.chageGroupList()
    this.draw()
    this.$store.dispatch('mouse/setCursor', 'default')
  },
  beforeUnmount: function () {
    // window.removeEventListener('resize', this.handleResize)
    this.$store.dispatch('canvas/setImage', null)
    this.$store.dispatch('tasks/setActivePlan', "")
    this.$store.dispatch('tasks/setActiveGroup', "")
    this.$store.dispatch('canvas/setContextMenu', false)
    this.$store.dispatch('materialsgroup/setGroupList', [])
    this.$store.dispatch('materialsgrouppoint/setActivePointList', [])
    // console.log("beforeUnmount")
  },
  props: {
    targetPlan: { type: String },
  },
  methods: {
    fitWindow: function(){
      if (this.setectedData == ""){
        this.clearCanvas()
        this.$store.dispatch('canvas/setImage', null)
      }else{
        this.draw()

        const cont = this.$refs.wrapper
        const contH = cont.clientHeight
        const contW = cont.clientWidth
        if (contH > 0) {
          this.$store.dispatch('canvas/setSize', {h: contH, w: contW})
        }
        this.$store.dispatch('mouse/setLastImgPosition', { x: 0, y: 0 })
        this.$store.dispatch('canvas/setScale', this.canvasRatio.x)
        this.$store.dispatch('canvas/setPrevScale', this.canvasRatio.x)
        this.draw()
      }
    },
    getMateriarlList(){

      let data = []
      let tempMaterialDict={}
      // 材料個別データ
      for (const m of this.materials) {
        if (this.activePlan==m.parent_data){
          tempMaterialDict[m.processing_no] = m
        }
      }
      // グループ情報
      let tempGroupDict={}
      for (const mg of this.materialsgroup) {
        if (this.activePlan==mg.parent_data){
          if (mg.required_num>0){
            if (mg.group_name in tempGroupDict){
              tempGroupDict[mg.group_name].push([mg.processing_no, mg.required_num])
            }else{
              tempGroupDict[mg.group_name] = [[mg.processing_no, mg.required_num]]
            }
          }
        }
      }
      //集計
      this.groupDict = tempGroupDict
      let sumMateriDict = {}
      for (const ap of this.activePointList){
        for (var key in tempGroupDict){
          if (ap.group_name==key){
            var dlist = tempGroupDict[key]
            for (var d of dlist){
              if (d[0] in sumMateriDict){
                sumMateriDict[d[0]] += d[1]
              }else{
                sumMateriDict[d[0]] = d[1]
              }
            }
          }
        }
      }
      //テーブルデータ作成
      for (var key2 in sumMateriDict){
        var md = tempMaterialDict[key2]
        data.push([key2, md.skelton_page_no, 
        md.shape_no, md.diameter, md.material, md.cutting_dimention, 
        md.cutting_total_num, md.standard_length, md.divided_num, 
        md.standard_num, md.standard_weight, md.cutting_weight, md.fixing_plate,
        sumMateriDict[key2]])
      }
      this.materialData = data
    },
    getMateriarlGroupList(){

      let data = []
      let tempMaterialDict={}
      // 材料個別データ
      for (const m of this.materials) {
        if (this.activePlan==m.parent_data){
          tempMaterialDict[m.processing_no] = m
        }
      }
      // グループ情報
      let tempGroupDict={}
      for (const mg of this.materialsgroup) {
        if (this.activePlan==mg.parent_data){
          if (mg.required_num>0){
            if (mg.group_name in tempGroupDict){
              tempGroupDict[mg.group_name].push([mg.processing_no, mg.required_num])
            }else{
              tempGroupDict[mg.group_name] = [[mg.processing_no, mg.required_num]]
            }
          }
        }
      }

      // テーブルデータ作成
      for (const d of tempGroupDict[this.setectedMaterial]) {
        var md = tempMaterialDict[d[0]]
        data.push([d[0], md.skelton_page_no, 
        md.shape_no, md.diameter, md.material, md.cutting_dimention, 
        md.cutting_total_num, md.standard_length, md.divided_num, 
        md.standard_num, md.standard_weight, md.cutting_weight, md.fixing_plate,
        d[1]])
      }
      this.materialData = data
    },
    moment(date, format) {
      return moment(date).format(format)
    },
    chageGroupList: function(){
      let groups = []
      
      for (let i=0; i<this.materialsgroup.length; i++){
        let mg = this.materialsgroup[i]
        if (mg.parent_data==this.activePlan){
          groups.push(this.materialsgroup[i]['group_name'])
        }
      }
      // console.log(groups)
      this.$store.dispatch('materialsgroup/setGroupList', Array.from(new Set(groups)))
      return Array.from(new Set(groups))
    },
    onWheel: function ($event) {
      const delta = $event.wheelDelta
      if (delta > 0) {
        if (this.scale > 2) return
        this.$store.dispatch('canvas/scaleUp')
      } else {
        if (this.scale < 0.1) return
        this.$store.dispatch('canvas/scaleDown')
      }
    },
  }
}
</script>